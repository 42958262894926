import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAPI } from '../../api/request';
import { useNavigate } from 'react-router';
import personOnBoard from '../../module/personOnBoardContacts';
import stepper from '../../module/stepper';
import _ from 'lodash';
import EditView from '../../views/Edit.view';
import AddView from '../../views/Add.view';
import Loading from '../../components/common/Loading.component';
import { Alert, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import Typography from '../../components/common/Typography.component';

const PersonOnBoardContactsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwt = process.env.REACT_APP_REGISTRATION_JWT;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const { data } = useSelector(personOnBoard.edit.selector.data);
  const selectContactsSelector = useSelector(
    personOnBoard.edit.selector.select
  );

  const theme = useTheme();

  const linkBack = useMemo(
    () => (
      <Link to='/registration/contacts_others'>
        <Typography
          variant='caption'
          pl={1}
          sx={{ color: theme.palette.primary.main }}
        >
          Forgot to add the contacts or the person on board in charge of
          deliveries? Click here to add it to the previous step.
        </Typography>
      </Link>
    ),
    [theme]
  );
  const onSuccess = (values) => {
    // Reset State
    setSuccess(true);
    setLoading(false);
    setError(null);

    // Dispatch Action
    dispatch(stepper.query.stepper.action({ next: 5 }));
    dispatch(personOnBoard.query.stepper.action(values));
    navigate(stepper.routes[5].path);
  };

  const onError = useCallback(
    (error) => {
      setError(
        error?.response?.data?.error?.message
          ? error?.response?.data?.error?.message
          : error?.message
      );
      setLoading(false);
      setSuccess(null);
    },
    [setLoading]
  );

  const onSubmit = (values) => {
    setSuccess(null);
    setLoading(true);
    setError(null);

    const promises = _.map(selectContactsSelector, (option) => {
      return fetchAPI(
        personOnBoard.query.edit.settings,
        { id: option.id },
        '',
        {
          is_delivery_manager_on_board:
            option.id === parseInt(values.contact) ? true : false,
        },
        jwt
      );
    });

    Promise.all(promises)
      .then(() => onSuccess(values))
      .catch((error) => onError(error));
  };

  const conf = useMemo(() => {
    return {
      ...personOnBoard.edit.conf,
      fields: _.map(personOnBoard.edit.conf.fields, (field) => {
        if (field.id) {
          return {
            ...field,
            options: selectContactsSelector,
          };
        }
        return field;
      }),
    };
    // eslint-disable-next-line
  }, [personOnBoard, selectContactsSelector]);

  return (
    <Fragment>
      {loading && <Loading />}
      {success && <Alert severity='success'>{'Success: Add'}</Alert>}
      {error && <Alert severity='error'>{error}</Alert>}
      {!_.isEmpty(data) ? (
        <EditView
          data={data}
          conf={conf}
          onEditSubmit={onSubmit}
          disabled={loading}
        >
          {linkBack}
        </EditView>
      ) : (
        <AddView conf={conf} onAddSubmit={onSubmit} disabled={loading}>
          {linkBack}
        </AddView>
      )}
    </Fragment>
  );
};

export default PersonOnBoardContactsPage;
