import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchAPI } from '../../../api/request';
import { actions } from '../slice';

function* loginSaga(action) {

    const { settings, params, query, body, token } = action.payload
    try {
        yield put({ type: actions.userLoading.type })
        const response = yield call(fetchAPI, settings, params, query, body, token);
        yield put({ type: actions.userSave.type, payload: response })
    } catch (error) {
        yield put({ type: actions.userError.type, payload: error.response?.data?.error?.message })
    }
}

export function* saga() {
    yield takeLatest(actions.userLoginAction, loginSaga)
}