import React from 'react';
import MUITextField from '@mui/material/TextField';
import Error from './Error.component';
import { useController, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const DatePicker = ({
  name,
  required,
  label,
  disableFuture = false,
  disablePast = false,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    field: { ref, onChange, value, ...inputProps },
  } = useController({
    name: name,
    control: control,
    rules: { required },
  });

  return (
    <Box display='flex' flexDirection={'column'}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          ref={ref}
          {...inputProps}
          disableFuture={disableFuture}
          disablePast={disablePast}
          value={dayjs(value)}
          label={label}
          inputFormat='DD-MM-YYYY'
          onChange={(value) => onChange(dayjs(value).format('YYYY-MM-DD'))}
          renderInput={(params) => (
            <MUITextField
              {...params}
              required={required}
              error={!!errors[name]?.message}
            />
          )}
        />
      </LocalizationProvider>
      <Error error={errors[name]?.message} />
    </Box>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
};

export default DatePicker;
