import { createSlice } from '@reduxjs/toolkit'
import { removeItem, saveItem } from '../../../utility/Storage.utility' //ok

export const loginSlice = (name, initialState) => createSlice({
    name,
    initialState: { ...initialState },
    reducers: {
        userSave: (state, action) => {
            state.loading = false
            state.error = null
            state.jwt = action.payload.jwt
            state.user = action.payload.user
            saveItem('jwt', action.payload.jwt)
            saveItem('user', action.payload.user)
        },
        userLogout: (state) => {
            state.loading = false
            state.error = null
            state.jwt = null
            state.user = null
            removeItem('jwt')
            removeItem('user')
        },
        userError: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        userLoading: (state) => {
            state.loading = true
            state.error = null
        },
    },
})