import React from 'react';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox from '@mui/material/Checkbox';
import { useController, useFormContext } from 'react-hook-form';
import { Box, TextField, useTheme } from '@mui/material';
import Error from './Error.component';
import MuiFormControl from '@mui/material/FormControl';
import MuiFormLabel from '@mui/material/FormLabel';
import MuiFormGroup from '@mui/material/FormGroup';

import PropTypes from 'prop-types';

const CheckboxGroup = ({ name, required, label, options = [] }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    field: { ref, onChange, value, ...inputProps },
  } = useController({
    name: name,
    control: control,
    rules: { required },
  });

  const theme = useTheme();

  return (
    <Box display='flex' flexDirection={'column'}>
      <MuiFormControl
        required={required}
        error={!!errors[name]?.message}
        component='fieldset'
        variant='standard'
      >
        <MuiFormLabel
          component='legend'
          sx={{ color: theme.palette.primary.main }}
        >
          {label}
        </MuiFormLabel>
        <MuiFormGroup ref={ref} {...inputProps}>
          {options?.map((item) => (
            <MuiFormControlLabel
              key={item.id}
              name={name}
              control={
                <MuiCheckbox
                  onChange={(e) =>
                    onChange({ ...value, [e.target.value]: e.target.checked })
                  }
                  value={item.id}
                />
              }
              label={
                item?.isInput ? (
                  <TextField
                    label='Other'
                    size='small'
                    onChange={(e) =>
                      onChange({ ...value, [e.target.value]: e.target.checked })
                    }
                  />
                ) : (
                  item.label
                )
              }
            />
          ))}
        </MuiFormGroup>
      </MuiFormControl>
      <Error error={errors[name]?.message} />
    </Box>
  );
};

CheckboxGroup.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
};

export default CheckboxGroup;
