import React from 'react';
import { Stack } from '@mui/system';
import OtherContactPage from './OtherContact.page';
import purserContacts from '../../module/purser';
import chiefStewardessContacts from '../../module/chiefStewardessContacts';
import firstOfficerContacts from '../../module/firstOfficerContacts';
import chefContacts from '../../module/chefContacts';
import stepper from '../../module/stepper';
import chiefEngineerContacts from '../../module/chiefEngineerContacts';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '../../components/common/Typography.component';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/common/Button.component';
import { Box, useTheme } from '@mui/material';
import otherContacts from '../../module/otherContacts';

const OthersDetails = [
  {
    id: 'purser',
    responsability: 'Purser',
    conf: purserContacts,
  },
  {
    id: 'chief_stewardess',
    responsability: 'Chief Stewardess',
    conf: chiefStewardessContacts,
  },
  {
    id: 'first_officer',
    responsability: 'First Officer',
    conf: firstOfficerContacts,
  },
  {
    id: 'chef',
    responsability: 'Chef',
    conf: chefContacts,
  },
  {
    id: 'chief_engineer',
    responsability: 'Chief Engineer',
    conf: chiefEngineerContacts,
  },
  {
    id: 'other',
    responsability: 'Other',
    conf: otherContacts,
  },
];

const OthersContactsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNext = () => {
    dispatch(stepper.query.stepper.action({ next: 4 }));
    navigate(stepper.routes[4].path);
  };

  const theme = useTheme();

  return (
    <Stack spacing={2}>
      {OthersDetails.map((item) => (
        <Accordion key={item.id} disableGutters elevation={1}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id={item.id}>
            <Typography
              sx={{
                fontWeight: 'bold',
                width: '100%',
                color: theme.palette.primary.main,
              }}
              variant='subtitle1'
            >
              {item.responsability}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <OtherContactPage
              responsability={item.responsability}
              conf={item.conf}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button color='primary' variant='contained' onClick={handleNext}>
          Skip
        </Button>
      </Box>
    </Stack>
  );
};

export default OthersContactsPage;
