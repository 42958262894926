import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  useTheme,
} from '@mui/material';
import React, { Fragment } from 'react';
import Typography from '../../components/common/Typography.component';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router-dom';
import { persistor, resetStateAction } from '../../redux/store';
import { useDispatch } from 'react-redux';

const InfoRegistrationFormPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const onClick = async () => {
    navigate('/registration/yacht_details');
    await persistor.purge();
    dispatch(resetStateAction());
  };

  const options = [
    {
      label: (
        <Typography>
          <b>Certificate of Registry</b> of the yacht -
          <span
            style={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}
          >
            {' '}
            COMPULSORY
          </span>
        </Typography>
      ),
    },
    {
      label: (
        <Typography>
          <b>Insurance </b> of the yacht -
          <span
            style={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}
          >
            {' '}
            COMPULSORY
          </span>
        </Typography>
      ),
    },
    {
      label: (
        <Typography>
          <b>Credit Card Details </b> (FOR GUARANTEE ONLY) -
          <span
            style={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}
          >
            {' '}
            COMPULSORY
          </span>{' '}
          in order to proceed with our services and advanced payments on your
          behalf. IT WILL NOT BE CHARGED WITHOUT YOUR AUTHORIZATION. We will
          keep the credit card details in safe until the payment of our invoices
          and it will be deleted after all your bills will be settled.
        </Typography>
      ),
    },
    {
      label: (
        <Typography>
          <b>Certificate of Incorporation </b> or <b>VAT number</b> -
          <span
            style={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}
          >
            {' '}
            COMPULSORY if billing to a Company
          </span>{' '}
          in order to proceed with all invoices. ONLY in case you have a VAT
          number or if we will need to bill to a Natural Person, this will not
          be required.
        </Typography>
      ),
    },
    {
      label: (
        <Typography>
          <b>Passport and Residential Address of Owner </b> -
          <span
            style={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}
          >
            {' '}
            COMPULSORY if billing to a Natural Person
          </span>{' '}
        </Typography>
      ),
    },
    {
      label: (
        <Typography>
          <b>Captain's Passport </b> -
          <span
            style={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}
          >
            {' '}
            COMPULSORY
          </span>{' '}
        </Typography>
      ),
    },
    {
      label: (
        <Typography>
          <b>Updated Crew List </b> - RECOMMENDED
        </Typography>
      ),
    },
    {
      label: (
        <Typography>
          <b>Final Signature </b> -
          <span
            style={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}
          >
            {' '}
            COMPULSORY
          </span>{' '}
          as at the end of the registration you will receive a copy of your
          contract filled and signed. In order to proceed with the signature,
          please draw your signature in the dedicated space.
        </Typography>
      ),
    },
  ];

  return (
    <Fragment>
      <Stack spacing={2}>
        <Typography sx={{ color: theme.palette.primary.main }}>
          Please follow all the instructions before proceeding with the online
          registration
        </Typography>
        <Typography
          sx={{ color: theme.palette.primary.main, fontSize: '14px' }}
        >
          In order to proceed with the online registration you will need:
        </Typography>

        <List dense={true}>
          {options.map((item, index) => (
            <ListItem key={index}>
              <ListItemIcon sx={{ minWidth: 20 }}>
                <FiberManualRecordIcon
                  style={{
                    width: 10,
                    height: 10,
                    color: theme.palette.primary.main,
                    position: 'absolute',
                    top: 14,
                  }}
                />
              </ListItemIcon>

              <ListItemText
                primary={item.label}
                style={{ color: theme.palette.primary.main }}
              />
            </ListItem>
          ))}
        </List>
      </Stack>

      <Typography
        sx={{
          textAlign: 'center',
          color: theme.palette.primary.main,
          fontSize: '14px',
        }}
      >
        Do not hesitate to contact us in case of any questions.
      </Typography>
      <Typography
        sx={{
          textAlign: 'center',
          color: theme.palette.grey.main,
          fontSize: '16px',
          pt: '20px',
        }}
      >
        PLEASE PROCEED WITH THE ONLINE REGISTRATION HERE BELOW:
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            width: { xs: '100%', md: '350px' },
            height: '84px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.grey.light,
            borderRadius: '3px',
          }}
        >
          <Button
            onClick={onClick}
            sx={{
              width: { xs: '422px', md: '289px' },
              backgroundColor: theme.palette.primary.main,
              color: 'white',
              fontSize: '15px',
              border: 'none',
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            REGISTRATION
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

export default InfoRegistrationFormPage;
