import React from 'react';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useFormContext } from 'react-hook-form';
import Error from './Error.component';
import PropTypes from 'prop-types';

const UploadButton = ({ label, name, required }) => {
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext();
  const watchFile = watch(name);

  return (
    <Box display='flex' flexDirection={'column'} sx={{ padding: 1 }}>
      <Button
        variant='outlined'
        startIcon={<FileUploadIcon />}
        component='label'
        color={!!errors[name]?.message ? 'error' : 'primary'}
      >
        {label}
        <input
          {...register(name)}
          multiple
          hidden
          // accept='file/*'
          type='file'
          required={required}
        />
      </Button>
      <Typography variant='subtitle1'>
        {`Choose a file ${required ? '*' : ''}`}
      </Typography>
      {watchFile &&
        Array.from(watchFile).map((file, index) => (
          <Typography
            key={index}
            variant='subtitle2'
            sx={{ fontWeight: 'bold' }}
          >
            {file.name}
          </Typography>
        ))}
      <Error error={errors[name]?.message} />
    </Box>
  );
};

UploadButton.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
};

export default UploadButton;
