import dayjs from 'dayjs';

export const API = {
  create: {
    path: '/send-attachment',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      return {
        data: {
          ...body,
          exp_date: dayjs(body?.exp_date).format('MM/YYYY'),
          type: body?.type?.label,
        },
      };
    },
  },
};
