import React from "react";
import MUIButton from '@mui/material/Button'

const Button = ({
    ...props
}) => {

    return (
        <MUIButton {...props} variant="contained" disableElevation={true} />
    )
}

export default Button

