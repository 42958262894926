import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Container, Paper, Stack } from '@mui/material';
import HeaderComponent from '../components/header/Header.component';
import FooterComponent from '../components/footer/Footer.component';

const MainLayout = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <HeaderComponent />
      <Container disableGutters={true} maxWidth={'md'} sx={{ height: '100%' }}>
        <Paper elevation={3} sx={{ margin: 5 }}>
          <Stack direction={'column'} spacing={2} sx={{ padding: 2 }}>
            <Outlet />
          </Stack>
        </Paper>
      </Container>
      <Box sx={{ marginTop: 'auto' }}>
        <FooterComponent />
      </Box>
    </Box>
  );
};

export default MainLayout;
