import * as yup from 'yup';

export const CreateConf = {
  schema: yup.object({
    invoice_person_name: yup
      .string()
      .nullable()
      .required('First name is required'),
    invoice_person_lastname: yup
      .string()
      .nullable()
      .required('Last name is required'),
    invoice_person_place_of_birth: yup
      .string()
      .nullable()
      .required('Place is required'),
    invoice_person_birthdate: yup
      .date()
      .nullable()
      .required('Birth Date is required'),
    invoice_street_address: yup
      .string()
      .nullable()
      .required('Street address is required'),
    invoice_city_address: yup.string().nullable().required('City is required'),
    invoice_country_address: yup
      .string()
      .nullable()
      .required('Country is required'),
    invoice_zipcode_addess: yup.string().nullable(),
    invoice_person_of_payment: yup
      .string()
      .nullable()
      .required('Person is required'),
    invoice_position: yup.string().nullable().required('Position is required'),
    invoice_email: yup
      .string()
      .nullable()
      .email('Please enter a valid email')
      .required('Email is required'),
    country_code: yup.object().nullable(),
    invoice_phone_number: yup
      .string()
      .nullable()
      .required('Phone number is required'),
    passaport: yup.mixed().nullable().required(),
  }),
  defaultValues: {
    invoice_person_name: '',
    invoice_person_lastname: '',
    invoice_person_place_of_birth: '',
    invoice_person_birthdate: null,
    invoice_street_address: '',
    invoice_street_address2: '',
    invoice_city_address: '',
    invoice_country_address: '',
    invoice_zipcode_addess: '',
    invoice_person_of_payment: '',
    invoice_position: '',
    invoice_email: '',
    country_code: null,
    invoice_phone_number: '',
    passaport: null,
  },
  fields: [
    {
      id: 'invoice_person_name',
      name: 'invoice_person_name',
      type: 'text',
      label: 'First Name',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_person_lastname',
      name: 'invoice_person_lastname',
      type: 'text',
      label: 'Last Name',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_person_place_of_birth',
      name: 'invoice_person_place_of_birth',
      type: 'text',
      label: 'Place of Birth',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_person_birthdate',
      name: 'invoice_person_birthdate',
      type: 'date',
      label: 'Birth date',
      required: true,
      disableFuture: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'residential_address',
      name: 'residential_address',
      type: 'typography',
      label: 'Residential Address',
      required: true,
      xs: 12,
      sm: 12,
    },
    {
      id: 'invoice_street_address',
      name: 'invoice_street_address',
      type: 'text',
      label: 'Street Address',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_street_address2',
      name: 'invoice_street_address2',
      type: 'text',
      label: 'Street Address Line 2',
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_city_address',
      name: 'invoice_city_address',
      type: 'text',
      label: 'City',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_country_address',
      name: 'invoice_country_address',
      type: 'text',
      label: 'Country',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_zipcode_addess',
      name: 'invoice_zipcode_addess',
      type: 'text',
      label: 'Postal / Zip Code',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'passaport',
      name: 'passaport',
      label: 'Copy of Passport - NATURAL PERSON',
      type: 'upload',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'divider_0',
      type: 'divider',
      xs: 12,
      sm: 12,
    },
    {
      id: 'invoice_person_of_payment',
      name: 'invoice_person_of_payment',
      type: 'text',
      label: 'Person in charge of payment',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_position',
      name: 'invoice_position',
      type: 'text',
      label: 'Position',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_email',
      name: 'invoice_email',
      type: 'text',
      label: 'Email',
      required: true,
      xs: 12,
      sm: 12,
    },
    {
      id: 'country_code',
      name: 'country_code',
      type: 'country-autocomplete',
      label: 'Country Code',
      required: false,
      xs: 4,
      sm: 4,
    },
    {
      id: 'invoice_phone_number',
      name: 'invoice_phone_number',
      type: 'number',
      label: 'Phone Number',
      required: true,
      xs: 8,
      sm: 8,
    },
  ],
};
