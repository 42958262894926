export const API = {
    update: {
        path: '/yachts/{{id}}',
        method: 'PUT',
        get: null,
        auth: true,
        fallback: null,
        adapter: (body) => {
            let obj = body;
            if (body?.country_code) {
                obj = {
                    ...body,
                    invoice_phone_number: `${body?.country_code.phone}-${body?.invoice_phone_number}`,
                };
                delete obj.country_code;
            }
            return {
                data: {
                    ...obj,
                }
            }
        }
    },
}
