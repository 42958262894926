import { combineReducers } from "@reduxjs/toolkit";
import { stepperSlice as createStepperSlice } from "../../../core/stepper/slice/Stepper.slice";

export const tag = "stepper";

// REDUCERS
const stepperSlice = createStepperSlice(`${tag}`, {});

export const reducer = combineReducers({
	stepper: stepperSlice.reducer,
});

// ACTIONS
export const actions = {
	...stepperSlice.actions,
};

