import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PropTypes from 'prop-types';
import Loading from './Loading.component';
import { Box } from '@mui/material';

const ConfirmDialog = ({
  open = false,
  onClose = () => {},
  title = '',
  description = '',
  cancelAction = () => {},
  confirmAction = () => {},
  loading,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {description}
          <Box m={2}>{loading && <Loading />}</Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelAction}>Cancel</Button>
        <Button onClick={confirmAction} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PropTypes.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  cancelAction: PropTypes.func,
  confirmAction: PropTypes.func,
};

export default ConfirmDialog;
