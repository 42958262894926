// REDUX
import { reducer, actions } from './slice';

// SELECTOR
import { tag } from './slice';

export { reducer, tag };

const stepper = {
  tag,
  query: {
    stepper: {
      action: actions.stepChange,
    },
  },
  routes: [
    {
      path: '/registration/yacht_details',
      title: 'Yacht Details',
      skippable: false,
      description:
        'Please fill in with yacht details and actual overall length.',
    },
    {
      path: '/registration/yacht_documents',
      title: 'Required Documents',
      skippable: false,
      description: "Please upload the yacht's updated documents.",
    },
    {
      path: '/registration/contacts_captain',
      title: "Captain's Contact Details",
      skippable: false,
      description:
        "Please choose your username and fill in with the captain's details and passport.",
    },
    {
      path: '/registration/contacts_others',
      title: 'Contacts on board',
      skippable: true,
      description:
        'Please fill in this section with the main contact details on board in order to facilitate yacht operations.',
    },
    {
      path: '/registration/contacts_person_on_board',
      title: 'Person on board in charge of Deliveries & Logistics',
      description:
        'Please choose the person on board responsible for logistics in order to arrange and schedule deliveries.',
    },
    {
      path: '/registration/invoice_details',
      title: 'Invoicing Details',
      description: 'Please insert your invoicing details here.',
    },
    {
      path: '/registration/terms_and_conditions',
      title: 'Terms & Conditions',
      description: '',
    },
    {
      path: '/registration/terms_of_payment',
      title: 'Terms of payment',
      description:
        'Please choose your payment preferences (you can choose more than one option).',
    },
    {
      path: '/registration/credit_card_details',
      title: 'Credit Card Authorization Form',
      description:
        "Please provide the yacht's credit card details in order to proceed with creating your account.",
    },
    {
      path: '/registration/signature_and_submission',
      title: 'Signature and Submission',
      description: 'Please sign and submit this form.',
    },
  ],
};

export default stepper;
