import * as yup from 'yup';
import dayjs from 'dayjs';

export const CreateConf = {
  schema: yup.object({
    client_of_signature: yup.string().nullable().required(),
    date_submit_signature: yup.date().nullable().required(),
    yacht_stamp: yup.mixed().nullable(),
    signature_draw: yup.mixed().nullable().required(),
  }),
  defaultValues: {
    client_of_signature: null,
    date_submit_signature: dayjs(),
    yacht_stamp: null,
    signature_draw: null,
  },
  fields: [
    {
      id: 'client_of_signature',
      name: 'client_of_signature',
      type: 'text',
      label: 'The Client (Printed Name)',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'date_submit_signature',
      name: 'date_submit_signature',
      type: 'date',
      label: 'Date of Submission ',
      disablePast: true,
      required: true,
      xs: 12,
      sm: 6,
    },

    {
      id: 'signature',
      type: 'typography',
      label: 'Signature ( please draw your signature here )*',
      xs: 12,
      sm: 12,
      sx: { p: 1 },
    },
    {
      id: 'signature_draw',
      name: 'signature_draw',
      type: 'draw',
      required: true,
      xs: 12,
      sm: 12,
    },
    {
      id: 'yacht_stamp',
      name: 'yacht_stamp',
      type: 'upload',
      label: 'Yacht stamp',
      xs: 12,
      sm: 6,
    },
  ],
};
