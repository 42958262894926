import { createSlice } from '@reduxjs/toolkit'


export const dataSlice = (name, initialState) => createSlice({
    name,
    initialState: {...initialState},
    reducers: {
        dataChange: (state, action) => {
            state.data = action.payload
            state.loading = false
            state.error = null
        },
        loadingChange: (state) => {
            state.loading = true
            state.error = null
        },
        errorChange: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.data = []
            state.meta = null
        },
    },
})