import * as yup from 'yup';
import { API as flags } from '../../flag/api';

export const CreateConf = {
  schema: yup.object({
    yacht_name: yup
      .string()
      .nullable()
      .required('Yacht Name is a required field'),
    yacht_type: yup
      .string()
      .nullable()
      .required('Yacht Type is a required field'),
    registry_type: yup
      .object()
      .nullable()
      .required('Registry is a required field'),
    port_of_registry: yup
      .string()
      .nullable()
      .required('Port of Registry is a required field'),
    id_flag: yup.object().nullable().required('Flag is a required field'),
    loa: yup.string().nullable().required('LOA is a required field'),
    beam: yup.string().nullable().required('BEAM is a required field'),
    draft: yup.string().nullable().required('DRAFT is a required field'),
    gross_tonn: yup
      .string()
      .nullable()
      .required('GROSS TONN is a required field'),
    id_nautica_known: yup
      .object()
      .nullable()
      .required('Nautica Known is a required field'),
    nautica_known_note: yup.string().nullable(),
  }),
  defaultValues: {
    yacht_name: '',
    yacht_type: null,
    registry_type: null,
    port_of_registry: '',
    id_flag: null,
    loa: '',
    beam: '',
    draft: '',
    gross_tonn: '',
    id_nautica_known: null,
    nautica_known_note: '',
  },
  fields: [
    {
      id: 'yacht_name',
      name: 'yacht_name',
      type: 'text',
      label: 'Yacht Name',
      required: true,
      xs: 12,
      sm: 12,
    },
    {
      id: 'yacht_type',
      name: 'yacht_type',
      label: 'Yacht Type',
      type: 'radio',
      options: [
        {
          label: 'M/Y',
          id: 'M/Y',
        },
        {
          label: 'S/Y',
          id: 'S/Y',
        },
      ],
      row: true,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'registry_type',
      name: 'registry_type',
      type: 'autocomplete',
      label: 'Registry',
      options: [
        {
          label: 'PRIVATE',
          id: 'PRIVATE',
        },
        {
          label: 'COMMERCIAL',
          id: 'COMMERCIAL',
        },
        {
          label: 'YET',
          id: 'YET',
        },
      ],
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'port_of_registry',
      name: 'port_of_registry',
      type: 'text',
      label: 'Port of Registry',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_flag',
      name: 'id_flag',
      type: 'async_autocomplete',
      label: 'Flag',
      api: flags.select,
      required: true,
      xs: 12,
      sm: 6,
    },

    {
      id: 'divider_0',
      type: 'divider',
      label: '',
      xs: 12,
      sm: 12,
    },
    {
      id: 'loa',
      name: 'loa',
      type: 'number',
      label: 'LOA (m)',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'beam',
      name: 'beam',
      type: 'number',
      label: 'BEAM (m)',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'draft',
      name: 'draft',
      type: 'number',
      label: 'DRAFT (m)',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'gross_tonn',
      name: 'gross_tonn',
      type: 'number',
      label: 'GROSS TONN',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'divider_0',
      type: 'divider',
      label: '',
      xs: 12,
      sm: 12,
    },
  ],
};
