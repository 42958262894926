export const API = {
  paymentType: {
    path: '/payment-preferences?populate=payment_type',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
    transform: (data) =>
      data?.data?.map((item) => ({
        label: item.attributes.payment_type,
        subLabel: ` (${item.attributes.description})`,
        id: item.id,
      })),
  },
};
