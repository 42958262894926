import React, { useCallback, Fragment, useState } from 'react';
import Loading from '../../components/common/Loading.component';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAPI } from '../../api/request';
import { useNavigate } from 'react-router';
import termsOfPayment from '../../module/termsOfPayment';
import EditView from '../../views/Edit.view';
import Alert from '../../components/common/Alert.component';
import stepper from '../../module/stepper';
import _isEmpty from 'lodash/isEmpty';
import AddView from '../../views/Add.view';

const TermsOfPaymentPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const jwt = process.env.REACT_APP_REGISTRATION_JWT;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { data } = useSelector(termsOfPayment.edit.selector.data);
  const idYacht = useSelector((state) => state.yacht_detail.data.id);

  const onError = useCallback(
    (error) => {
      setError(
        error?.response?.data?.error?.message
          ? error?.response?.data?.error?.message
          : error?.message
      );
      setLoading(false);
      setSuccess(null);
    },
    [setLoading]
  );

  const onSuccess = (values) => {
    // Reset State
    setSuccess(true);
    setLoading(false);
    setError(null);

    // Dispatch Action
    dispatch(stepper.query.stepper.action({ next: 8 }));
    dispatch(termsOfPayment.query.stepper.action(values));
    navigate(stepper.routes[8].path);
  };

  const onSubmitEdit = (values) => {
    setSuccess(null);
    setLoading(true);
    setError(null);

    fetchAPI(
      termsOfPayment.query.edit.settings,
      { id: idYacht },
      '',
      {
        ...values,
        payment_preferences: Object.keys(values?.payment_preferences).filter(
          (key) => values?.payment_preferences[key]
        ),
      },
      jwt
    )
      .then(() => onSuccess(values))
      .catch((error) => onError(error));
  };

  return (
    <Fragment>
      {loading && <Loading />}
      {error && <Alert severity='error'>{error}</Alert>}
      {success && <Alert severity='success'>{'Success: Add'}</Alert>}
      {!_isEmpty(data) && idYacht ? (
        <EditView
          data={data}
          conf={termsOfPayment.edit.conf}
          onEditSubmit={onSubmitEdit}
          disabled={loading}
        />
      ) : (
        <AddView
          conf={termsOfPayment.edit.conf}
          onAddSubmit={onSubmitEdit}
          disabled={loading}
        />
      )}
    </Fragment>
  );
};

export default TermsOfPaymentPage;
