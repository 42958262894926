import dayjs from 'dayjs';

export const API = {
  update: {
    path: '/yachts/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      return {
        data: {
          ...body,
          date_submit_signature: body?.date_submit_signature
            ? dayjs(body?.date_submit_signature).format('YYYY-MM-DD')
            : null,
        },
      };
    },
  },
};
