import Divider from './components/Divider.component';
import Autocomplete from './components/Autocomplete.component';
import TextField from './components/TextField.component';
import AsyncAutocomplete from './components/AsyncAutocomplete.component';
import UploadButton from './components/Upload.component';
import DatePicker from './components/DatePicker.component';
import RadioButtons from './components/RadioButtons.component';
import Checkbox from './components/Checkbox.component';
import Typography from '../common/Typography.component';
import CheckboxGroup from './components/CheckboxGroup.component';
import DrawComponent from './components/Draw.component';
import PropTypes from 'prop-types';
import CountryAutocompelte from './components/CountryAutocomplete.component';
import AsyncCheckboxGroup from './components/AsyncCheckboxGroup.component';
import MonthYearPicker from './components/MonthYearPicker.component';
export const DynamicControl = ({
  id,
  name,
  type,
  label,
  options = [],
  url,
  api,
  required,
  sx,
  disableFuture,
  disablePast,
  disabled,
  row,
  size,
}) => {
  switch (type) {
    case 'text':
      return (
        <TextField
          name={name}
          required={required}
          id={id}
          label={label}
          disabled={disabled}
          size={size}
        />
      );
    case 'password':
      return (
        <TextField
          name={name}
          required={required}
          id={id}
          label={label}
          disabled={disabled}
          type='password'
        />
      );
    case 'number':
      return (
        <TextField
          name={name}
          required={required}
          id={id}
          label={label}
          disabled={disabled}
          type='number'
        />
      );
    case 'autocomplete':
      return (
        <Autocomplete
          name={name}
          required={required}
          id={id}
          label={label}
          options={options}
        />
      );
    case 'async_autocomplete':
      return (
        <AsyncAutocomplete
          name={name}
          required={required}
          id={id}
          label={label}
          url={url}
          api={api}
        />
      );
    case 'divider': {
      return <Divider label={label} />;
    }
    case 'upload': {
      return (
        <UploadButton name={name} required={required} id={id} label={label} />
      );
    }
    case 'date': {
      return (
        <DatePicker
          name={name}
          required={required}
          id={id}
          label={label}
          disableFuture={disableFuture}
          disablePast={disablePast}
        />
      );
    }
    case 'date_month_year':
      return (
        <MonthYearPicker
          name={name}
          required={required}
          id={id}
          label={label}
          disableFuture={disableFuture}
          disablePast={disablePast}
        />
      );
    case 'radio': {
      return (
        <RadioButtons
          name={name}
          required={required}
          id={id}
          options={options}
          label={label}
          disabled={disabled}
          row={row}
        />
      );
    }
    case 'checkbox_group': {
      return (
        <CheckboxGroup
          name={name}
          required={required}
          id={id}
          label={label}
          options={options}
        />
      );
    }
    case 'checkbox': {
      return <Checkbox name={name} required={required} id={id} label={label} />;
    }
    case 'typography': {
      return <Typography {...sx}>{label}</Typography>;
    }
    case 'iframe': {
      return <iframe src={url} title={label} style={{ width: '100%' }} />;
    }
    case 'draw': {
      return <DrawComponent name={name} required={required} />;
    }
    case 'country-autocomplete':
      return (
        <CountryAutocompelte
          name={name}
          required={required}
          id={id}
          label={label}
          options={options}
        />
      );
    case 'async-checkbox_group':
      return (
        <AsyncCheckboxGroup
          name={name}
          required={required}
          id={id}
          options={options}
          label={label}
          disabled={disabled}
          row={row}
          url={url}
          api={api}
        />
      );
    default:
      return null;
  }
};

DynamicControl.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.node,
  options: PropTypes.array,
  url: PropTypes.string,
  required: PropTypes.bool,
  sx: PropTypes.object,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
};
