import { Stack, useTheme, Box } from '@mui/material';
import React from 'react';
import Typography from '../../components/common/Typography.component';
import Button from '../../components/common/Button.component';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { persistor, resetStateAction } from '../../redux/store';

const SuccessRegistrationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { data: dataCaptain } = useSelector((state) => state.captain_contacts);
  const { data: dataYacht } = useSelector((state) => state.yacht_detail);

  const onClick = async () => {
    navigate('/home');
    await persistor.purge();
    dispatch(resetStateAction());
  };

  return (
    <Stack spacing={4} justifyContent={'center'} alignItems={'center'}>
      <Typography
        variant='h5'
        color='primary'
        sx={{ color: theme.palette.primary.main }}
      >
        {`Thank You, ${dataCaptain?.name}`}
      </Typography>

      <Box>
        <Typography color='primary' align='center'>
          {`Your submission has been received and ${dataYacht?.yacht_type} ${dataYacht?.yacht_name}`}
        </Typography>
        <Typography color='primary' align='center'>
          has been successfully registered!
        </Typography>
      </Box>

      <Box>
        <Typography color='primary' align='center'>
          Check your inbox to view our NA Customer Registration Form
        </Typography>
        <Typography color='primary' align='center'>
          successfully filled and signed.
        </Typography>
      </Box>
      <Button
        onClick={onClick}
        variant='contained'
        sx={{
          backgroundColor: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
        }}
      >
        Home
      </Button>
    </Stack>
  );
};

export default SuccessRegistrationPage;
