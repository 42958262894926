import * as yup from 'yup';

export const UpdateConf = {
  schema: yup.object({
    contact: yup.string().nullable().required(),
  }),
  defaultValues: {
    contact: null,
  },
  fields: [
    {
      id: 'divider_0',
      type: 'divider',
      label: 'Person on board in charge of Deliveries & Logistics',
      xs: 12,
      sm: 12,
    },
    {
      id: 'contact',
      name: 'contact',
      type: 'radio',
      label: 'Contact',
      options: [],
      row: false,
      required: true,
      xs: 12,
      sm: 12,
    },
  ],
};
