import React, { useEffect, useState } from 'react';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox from '@mui/material/Checkbox';
import { useController, useFormContext } from 'react-hook-form';
import { Box, TextField, useTheme } from '@mui/material';
import Error from './Error.component';
import MuiFormControl from '@mui/material/FormControl';
import MuiFormLabel from '@mui/material/FormLabel';
import MuiFormGroup from '@mui/material/FormGroup';
import { fetchAPI } from '../../../api/request';
import MuiTypography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Loading from '../../common/Loading.component';

const AsyncCheckboxGroup = ({ name, required, label, api, url }) => {
  const [options, setOptions] = useState([]);
  const jwt = process.env.REACT_APP_REGISTRATION_JWT;
  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    field: { ref, onChange, value, ...inputProps },
  } = useController({
    name: name,
    control: control,
    rules: { required },
  });

  useEffect(() => {
    setLoading(true);
    fetchAPI(api, {}, {}, {}, jwt)
      .then((response) => {
        setLoading(false);
        setOptions(response);
      })
      .catch(() => {
        setLoading(false);
        setOptions([]);
      });
  }, [jwt, api]);

  const theme = useTheme();

  return (
    <Box display='flex' flexDirection={'column'}>
      <MuiFormControl
        required={required}
        error={!!errors[name]?.message}
        component='fieldset'
        variant='standard'
      >
        <MuiFormLabel
          component='legend'
          sx={{ color: theme.palette.primary.main }}
        >
          {label}
        </MuiFormLabel>
        {loading ? (
          <Loading />
        ) : (
          <MuiFormGroup ref={ref} {...inputProps}>
            {options?.map((item) => (
              <MuiFormControlLabel
                key={item.id}
                name={name}
                control={
                  <MuiCheckbox
                    onChange={(e) =>
                      onChange({ ...value, [e.target.value]: e.target.checked })
                    }
                    checked={value && value[item?.id]}
                    value={item.id}
                  />
                }
                label={
                  item?.isInput ? (
                    <TextField
                      label='Other'
                      size='small'
                      onChange={(e) =>
                        onChange({
                          ...value,
                          [e.target.value]: e.target.checked,
                        })
                      }
                    />
                  ) : (
                    <MuiTypography sx={{ lineHeight: 1 }}>
                      {item.label}
                      <MuiTypography variant='caption'>
                        {item.subLabel}
                      </MuiTypography>
                    </MuiTypography>
                  )
                }
              />
            ))}
          </MuiFormGroup>
        )}
      </MuiFormControl>
      <Error error={errors[name]?.message} />
    </Box>
  );
};

AsyncCheckboxGroup.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
};

export default AsyncCheckboxGroup;
