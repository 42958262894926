import * as yup from "yup";

const schema = yup.object({
    email: yup.string().email().required(),
}).required();

const defaultValues = {
    email: 'marco.melis@abinsula.com',
}

const fields = [{
    id: "email",
    name: "email",
    type: "text",
    label: "Email",
    required: true,
    xs: 12,
}]

export const ForgotConf = {
    schema,
    defaultValues,
    fields
}