import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';

const FormComponent = ({ onSubmit = () => {}, children }) => {
  const theme = useTheme();
  return (
    <Box
      component='form'
      onSubmit={onSubmit}
      sx={{
        display: 'flex',
        width: 1,
        flexDirection: 'column',
        color: theme.palette.primary.main,
      }}
      noValidate
    >
      {children}
    </Box>
  );
};

FormComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default FormComponent;
