import React, { Fragment, useCallback } from 'react';
import Update from '../components/crud/Update'

const EditView = ({
    conf,
    onEditSubmit = () => { },
    disabled,
    children,
    data
}) => {
    const { fields, schema } = conf;
    const onSubmit = useCallback(
        (values) => {
            onEditSubmit(values);
            setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 10);
        },
        [onEditSubmit]
    );
    
    return (
        <Fragment>
            {data && (
                <Update
                    defaultValues={data}
                    onSubmit={onSubmit}
                    disabled={disabled}
                    fields={fields}
                    schema={schema}>
                    {children}
                </Update>
            )}
        </Fragment>
    );
};

export default EditView;
