import React, { Fragment, useCallback, useState, useEffect } from 'react';
import Loading from '../../components/common/Loading.component';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../components/common/Alert.component';
import { fetchAPI } from '../../api/request';
import { useNavigate } from 'react-router';
import naturalPersonDetails from '../../module/naturalPersonDetails';
import invoiceDetail from '../../module/invoiceDetail';
import yachtDocuments from '../../module/yachtDocuments';
import EditView from '../../views/Edit.view';
import stepper from '../../module/stepper';
import _isEmpty from 'lodash/isEmpty';
import AddView from '../../views/Add.view';
import _omit from 'lodash/omit';
import _concat from 'lodash/concat';
import _flatten from 'lodash/flatten';
import _find from 'lodash/find';
import _drop from 'lodash/drop';
import companyDetails from '../../module/companyDetails';
import dayjs from 'dayjs';

const NaturalPersonDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jwt = process.env.REACT_APP_REGISTRATION_JWT;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { data } = useSelector(naturalPersonDetails.edit.selector.data);
  const { data: documents } = useSelector(yachtDocuments.edit.selector.data);
  const idYacht = useSelector((state) => state.yacht_detail.data.id);
  const [types, setTypes] = useState([]);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    fetchAPI(yachtDocuments.query.types.settings, {}, '', {}, jwt).then(
      (response) => setTypes(response)
    );
    // eslint-disable-next-line
  }, []);

  const onError = useCallback(
    (error) => {
      setError(
        error?.response?.data?.error?.message
          ? error?.response?.data?.error?.message
          : error?.message
      );
      setLoading(false);
      setSuccess(null);
    },
    [setLoading]
  );

  const onSuccess = (values, response) => {
    //[{ ..._omit(values, 'passaport'), id: responseForm.data.id }, { ...responseDocument.data.attributes.file.data.attributes, type: responseDocument.data.attributes.id_document_type.data.attributes.slug }]
    // Reset State
    setLoading(false);
    setError(null);
    setSuccess(true);

    // Dispatch Action
    dispatch(
      naturalPersonDetails.query.stepper.action({
        ..._omit(values, 'passaport'),
        invoice_person_birthdate: values?.invoice_person_birthdate
          ? dayjs(values?.invoice_person_birthdate).format('YYYY-MM-DD')
          : null,
        id: response[0].data.id,
      })
    );
    dispatch(companyDetails.query.stepper.action(null));

    dispatch(
      invoiceDetail.query.stepper.action({ invoicing_entity: 'NATURAL PERSON' })
    );

    if (response.length > 1) {
      const docs = _drop(response).map((item) => ({
        ...item.data.attributes.file.data.attributes,
        type: item.data.attributes.id_document_type.data.attributes.slug,
      }));
      dispatch(
        yachtDocuments.query.stepper.action(
          _flatten(_concat([...documents], ...docs))
        )
      );
    }

    dispatch(stepper.query.stepper.action({ next: 6 }));
    navigate(stepper.routes[6].path);
  };

  const onSubmitEdit = (values) => {
    setLoading(true);
    setSuccess(null);
    setError(null);

    const promiseForm = fetchAPI(
      naturalPersonDetails.query.edit.settings,
      { id: idYacht },
      '',
      {
        ..._omit(values, 'passaport'),
        invoicing_entity: 'NATURAL PERSON',
        vat_number_company_invoicing: null,
        vat_document: null,
        invoice_company_name: null,
      },
      jwt
    );
    const documentType = _find(types, (t) => t.attributes.slug === 'pass-port');
    const promiseDocument = Array.from(values.passaport).map((file) =>
      fetchAPI(
        yachtDocuments.query.create.settings,
        {},
        '',
        { file, idYacht, documentType: documentType?.id },
        jwt
      )
    );
    Promise.all(_flatten([promiseForm, promiseDocument]))
      .then((response) => onSuccess(values, response))
      .catch((error) => onError(error));
  };

  return (
    <Fragment>
      {loading && <Loading />}
      {error && <Alert severity='error'>{error}</Alert>}
      {success && <Alert severity='success'>{'Success'}</Alert>}

      {types.length > 0 ? (
        !_isEmpty(data) && idYacht ? (
          <EditView
            data={data}
            conf={naturalPersonDetails.edit.conf}
            onEditSubmit={onSubmitEdit}
            disabled={loading}
          />
        ) : (
          <AddView
            conf={naturalPersonDetails.edit.conf}
            onAddSubmit={onSubmitEdit}
            disabled={loading}
          />
        )
      ) : null}
    </Fragment>
  );
};

export default NaturalPersonDetailsPage;
