import * as yup from 'yup';
import { PaymentTypes } from './Data.conf';
export const CreateConf = {
  schema: yup.object({
    type: yup.object().nullable().required('Card type is required'),
    exp_date: yup
      .date()
      .nullable()
      .required('Expiration date is required')
      .test(
        'valid-exp-date',
        'Expiration date must be in the future',
        function (value) {
          if (value) {
            const currentDate = new Date();
            const selectedDate = new Date(value);

            return (
              selectedDate >=
              new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
            );
          }
          return true;
        }
      ),
    name: yup
      .string()
      .nullable()
      .required('Name as appears on card is required'),
    number: yup
      .string()
      .nullable()
      .required('Credit card number is required')
      .when(['type'], (type, schema) => {
        return type?.label === 'AMEX'
          ? schema.test(
              'is-fifteen-digits',
              'Number must be 15 digits long for AMEX type',
              function (value) {
                if (value !== null && value !== '') {
                  return value.replace(/\D/g, '').length === 15;
                }
                return true;
              }
            )
          : schema.test(
              'is-sixteen-digits',
              'Number must be 16 digits long',
              function (value) {
                if (value !== null && value !== '') {
                  return value.replace(/\D/g, '').length === 16;
                }
                return true;
              }
            );
      }),
  }),

  defaultValues: {
    type: null,
    exp_date: null,
    name: null,
    number: null,
  },
  fields: [
    {
      id: 'info_1',
      type: 'typography',
      label:
        'By filling this form I hereby agree to provide Nautica Assistance with credit card details as a form of guarantee for the services rendered.I grant permission to use the present credit card in case the invoice/s will not be settled within 15 days from the receipt.We will keep the credit card details safe until the payment of all our invoices have been settled and it will be deleted immediately after.',
      xs: 12,
      sm: 12,
    },

    {
      id: 'type',
      name: 'type',
      type: 'autocomplete',
      label: 'Card Type',
      options: PaymentTypes,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'number',
      name: 'number',
      type: 'number',
      label: 'Credit Card Number',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'exp_date',
      name: 'exp_date',
      type: 'date_month_year',
      label: 'Expiration Date',
      disablePast: true,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'name',
      name: 'name',
      type: 'text',
      label: 'Name as appears on card',
      required: true,
      xs: 12,
      sm: 6,
    },
  ],
};
