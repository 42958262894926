import React from 'react';
import { Box, Button,  Typography } from '@mui/material';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import { Link } from 'react-router-dom';
import pageNotFound from '../assets/page_not_found.png';

const NotFoundPage = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
      }}
    >
      <Box
        sx={{
          maxWidth: '600px',
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        <Typography
          variant='h1'
          component='div'
          sx={{ fontSize: { xs: '30px', sm: '40px', md: '50px' } }}
        >
          Something is wrong
        </Typography>
        <Box>
          <img
            src={pageNotFound}
            alt='Page Not Found'
            style={{ width: '100%' }}
          />
        </Box>
        <Typography variant='body2' sx={{ marginBottom: '20px' }}>
          The page you are looking was moved, removed, renamed, or might never
          exist!{' '}
        </Typography>
        <Box>
          <Button variant='contained' size='large' component={Link} to={'/'}>
            <HomeTwoToneIcon sx={{ fontSize: '1.3rem', mr: 0.75 }} /> Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
