import { Grid } from '@mui/material';
import React, { useRef } from 'react';
import CanvasDraw from 'react-canvas-draw';
import { useController, useFormContext } from 'react-hook-form';
import Button from '../../common/Button.component';
import Error from './Error.component';

import PropTypes from 'prop-types';

function urltoFile(url, filename, mimeType) {
    return (fetch(url)
        .then(function (res) { return res.arrayBuffer(); })
        .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
}


const DrawComponent = ({
    name,
    required
}) => {

    const firstCanvas = useRef(null);

    const { control, formState: { errors }, register } = useFormContext();
    const { field: { onChange } } = useController({ name: name, control: control, required });

    const handleClick = () => {
        const base64 = firstCanvas.current.getDataURL();
        urltoFile(base64, 'signature.png', 'image/png').then(function (file) { onChange(file) });
    };


    const clear = () => {
        firstCanvas.current.clear();
        onChange(null);
    };

    return (
        <Grid container sx={{ width: '100%' }}>
            <Grid item xs={12}>
                <CanvasDraw
                    ref={firstCanvas}
                    onChange={handleClick}
                    brushRadius={1}
                    style={{ width: '100%', borderRadius: 1, border: !!errors[name]?.message ? '1px solid  red' : '1px solid  black', margin: 1 }}
                />
                <input {...register(name)} hidden type="file" />
            </Grid>

            <Grid item xs={12} display='flex' justifyContent='end'>                
                <Button onClick={clear}>Clear</Button>
            </Grid>
            <Grid item>
                <Error error={errors[name]?.message} />
            </Grid>
        </Grid>
    );
};

DrawComponent.propTypes = {
    name: PropTypes.string,
};

export default DrawComponent;
