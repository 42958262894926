import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Loading from '../../components/common/Loading.component';
import { useSelector } from 'react-redux';
import Alert from '../../components/common/Alert.component';
import { fetchAPI } from '../../api/request';
import { useNavigate } from 'react-router';
import signatureAndSubmission from '../../module/signatureAndSubmission';
import yachtDocuments from '../../module/yachtDocuments';
import EditView from '../../views/Edit.view';
import ConfirmDialog from '../../components/common/Confirm.component';
import AddView from '../../views/Add.view';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _flatten from 'lodash/flatten';
import _find from 'lodash/find';
import creditCardDetails from '../../module/creditCardDetails';
import _ from 'lodash';

const SignatureAndSubmissionPage = () => {
  const navigate = useNavigate();
  const jwt = process.env.REACT_APP_REGISTRATION_JWT;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { data } = useSelector(signatureAndSubmission.edit.selector.data);
  const { data: dataCreditCard } = useSelector(
    creditCardDetails.edit.selector.data
  );

  const idYacht = useSelector((state) => state.yacht_detail.data.id);
  const nameYacht = useSelector((state) => state.yacht_detail.data.yacht_name);

  const [values, setValues] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [types, setTypes] = useState([]);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    fetchAPI(yachtDocuments.query.types.settings, {}, '', {}, jwt).then(
      (response) => setTypes(response)
    );
  }, [jwt]);

  const onError = useCallback(
    (error) => {
      setError(
        error?.response?.data?.error?.message
          ? error?.response?.data?.error?.message
          : error?.message
      );
      setLoading(false);
      setSuccess(null)
    },
    [setLoading]
  );

  const onClose = () => {
    setOpenConfirmDialog(false);
  };

  const onOpen = (values) => {
    setValues(values);
    setOpenConfirmDialog(true);
  };

  const onSendAttachments = useCallback(() => {
    if (!_.every(dataCreditCard, _.isNull)) {
      fetchAPI(
        creditCardDetails.query.create.settings,
        {},
        '',
        { ...dataCreditCard, yacht_id: idYacht, yacht_name: nameYacht },
        jwt
      )
        .then(() => onSuccess())
        .catch(() => onSuccess());
    } else {
      onSuccess();
    }
    // eslint-disable-next-line
  }, [dataCreditCard, idYacht, jwt, nameYacht]);

  const onSuccess = () => {
    // Reset State
    setLoading(false);
    setError(null);
    setSuccess(true)

    // Dispatch Action
    navigate('/registration/success');

    // await persistor.purge();
    // dispatch(resetStateAction());
    // onClose();
  };

  const onSubmit = (values) => {
    setLoading(true);
    setSuccess(null)
    setError(null)
    
    const documentType = _find(types, (t) => t.attributes.slug === 'signature');
    const promiseForm = fetchAPI(
      signatureAndSubmission.query.edit.settings,
      { id: idYacht },
      '',
      {
        ..._omit(values, ['signature_draw', 'yacht_stamp']),
        registration_status: 'ONLINE',
      },
      jwt
    );
    const promiseDocument = fetchAPI(
      yachtDocuments.query.create.settings,
      {},
      '',
      {
        file: values.signature_draw,
        idYacht,
        documentType: documentType.id,
      },
      jwt
    );

    const documentTypeStamp = _find(
      types,
      (t) => t.attributes.slug === 'yacht-stamp'
    );
    const promiseStamp = Array.from(values.yacht_stamp || []).map((file) =>
      fetchAPI(
        yachtDocuments.query.create.settings,
        {},
        '',
        {
          file,
          idYacht,
          documentType: documentTypeStamp.id,
        },
        jwt
      )
    );
    Promise.all(_flatten([promiseForm, promiseDocument, promiseStamp]))
      .then(() => onSendAttachments())
      .catch((error) => onError(error));
  };

  return (
    <Fragment>
      {loading && <Loading />}
      {error && <Alert severity='error'>{error}</Alert>}
      {success && <Alert severity='success'>{'Success'}</Alert>}

      {!_isEmpty(data) && idYacht ? (
        <EditView
          data={data}
          conf={signatureAndSubmission.edit.conf}
          onEditSubmit={onOpen}
          disabled={loading}
        />
      ) : (
        <AddView
          conf={signatureAndSubmission.create.conf}
          onAddSubmit={onOpen}
          disabled={loading}
        />
      )}
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={onClose}
        cancelAction={onClose}
        description={
          'You will not be able to retrieve or modify this submission. Do you wish to continue?'
        }
        confirmAction={() => onSubmit(values)}
        title='Complete Submission'
        loading={loading}
      />
    </Fragment>
  );
};

export default SignatureAndSubmissionPage;
