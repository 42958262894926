import React, { Fragment, useCallback, useState } from 'react';
import Loading from '../../components/common/Loading.component';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../components/common/Alert.component';
import { fetchAPI } from '../../api/request';
import EditView from '../../views/Edit.view';
import AddView from '../../views/Add.view';
import _isEmpty from 'lodash/isEmpty';
import rotateCaptainContacts from '../../module/rotateCaptain';
import captainContacts from '../../module/captainContacts';
import dayjs from 'dayjs';
const conf = rotateCaptainContacts;
const RotateCaptainContactPage = () => {
  const dispatch = useDispatch();
  const jwt = process.env.REACT_APP_REGISTRATION_JWT;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const idYacht = useSelector((state) => state.yacht_detail.data.id);
  const { data } = useSelector(conf.edit.selector.data);
  const { data: dataCaptain } = useSelector(captainContacts.edit.selector.data);

  const onError = useCallback(
    (error) => {
      setError(
        error?.response?.data?.error?.message
          ? error?.response?.data?.error?.message
          : error?.message
      );
      setLoading(false);
      setSuccess(null);
    },
    [setLoading]
  );

  const onSuccess = useCallback(
    (values) => {
      // Reset State
      setSuccess(true);
      setLoading(false);
      setError(null);

      // Dispatch Action
      dispatch(
        conf.query.stepper.action({
          ...values,
          birthdate: values?.birthdate
            ? dayjs(values?.birthdate).format('YYYY-MM-DD')
            : null,
          start_date: values?.start_date
            ? dayjs(values?.start_date).format('YYYY-MM-DD')
            : null,
          end_date: values?.end_date
            ? dayjs(values?.end_date).format('YYYY-MM-DD')
            : null,
        })
      );
    },
    // eslint-disable-next-line
    [data]
  );

  const onSubmitEdit = useCallback(
    (values) => {
      setSuccess(null);
      setLoading(true);
      setError(null);
      const obj = {
        ...values,
        email: dataCaptain.email,
        username: `${dataCaptain.username}_${values.name}`,
      };
      fetchAPI(conf.query.edit.settings, { id: data.id }, '', { ...obj }, jwt)
        .then(() => onSuccess(obj))
        .catch((error) => onError(error));
    },
    [dataCaptain, jwt, data, onError, onSuccess]
  );

  const onSubmitAdd = useCallback(
    (values) => {
      setSuccess(null);
      setLoading(true);
      setError(null);
      const obj = {
        ...values,
        email: dataCaptain.email,
        username: `${dataCaptain.username}_${values.name}`,
      };

      fetchAPI(
        conf.query.create.settings,
        {},
        '',
        { ...obj, crew_responsability: 'Rotate Captain', id_yacht: idYacht },
        jwt
      )
        .then((response) => onSuccess({ ...obj, id: response.data.id }))
        .catch((error) => onError(error));
    },
    [dataCaptain, jwt, idYacht, onSuccess, onError]
  );

  if (!_isEmpty(data) && idYacht) {
    return (
      <Fragment>
        {loading && <Loading />}
        {success && <Alert severity='success'>{'Success'}</Alert>}
        {error && <Alert severity='error'>{error}</Alert>}
        <EditView
          data={data}
          conf={conf.edit.conf}
          onEditSubmit={onSubmitEdit}
          disabled={loading}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      {loading && <Loading />}
      {success && <Alert severity='success'>{'Success'}</Alert>}
      {error && <Alert severity='error'>{error}</Alert>}
      <AddView
        conf={conf.create.conf}
        onAddSubmit={onSubmitAdd}
        disabled={loading}
      />
    </Fragment>
  );
};

export default RotateCaptainContactPage;
