import React from 'react';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

const Error = ({ error }) => {
  return <Typography variant='error'>{error}</Typography>;
};

Error.propTypes = {
  error: PropTypes.string,
};

export default Error;
