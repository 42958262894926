import dayjs from 'dayjs';

export const API = {
  create: {
    path: '/users-crews',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      let obj = body;
      if (body?.country_code) {
        obj = {
          ...body,
          phone_number: `${body?.country_code?.phone}-${body?.phone_number}`,
        };
      }
      delete obj.country_code;
      return {
        data: {
          ...obj,
          birthdate: body?.birthdate
            ? dayjs(body?.birthdate).format('YYYY-MM-DD')
            : null,
        },
      };
    },
  },
  update: {
    path: '/users-crews/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      let obj = body;
      if (body?.country_code) {
        obj = {
          ...body,
          phone_number: `${body?.country_code?.phone}-${body?.phone_number}`,
        };
      }
      delete obj.country_code;
      return {
        data: {
          name: obj?.name,
          surname: obj?.surname,
          phone_number: obj?.phone_number,
          birthdate: obj?.birthdate
            ? dayjs(obj?.birthdate).format('YYYY-MM-DD')
            : null,
        },
      };
    },
  },
};
