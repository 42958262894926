// CONF
import { CreateConf } from './conf/Create.conf';

// REDUX
import { reducer, actions, editStepperSelector } from './slice';

// SELECTOR
import { tag } from './slice';

// API
import { API } from './api';
import { UpdateConf } from './conf/Update.conf';

export { reducer, tag };

const otherContacts = {
  tag,
  query: {
    stepper: {
      action: actions.dataChange,
    },
    create: {
      settings: API.create,
    },
    edit: {
      settings: API.update,
    },
  },
  create: {
    conf: CreateConf,
  },
  edit: {
    conf: UpdateConf,
    selector: {
      data: editStepperSelector,
    },
  },
};

export default otherContacts;
