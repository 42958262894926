import React, { Fragment, useCallback, useState } from 'react';
import Loading from '../../components/common/Loading.component';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../components/common/Alert.component';
import { fetchAPI } from '../../api/request';
import yachtDetail from '../../module/yachtDetail';
import EditView from '../../views/Edit.view';
import stepper from '../../module/stepper';
import AddView from '../../views/Add.view';
import { useNavigate } from 'react-router-dom';
import NauticaKnownForm from '../../components/yacht/NauticaKnownForm.component';

const YachtDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwt = process.env.REACT_APP_REGISTRATION_JWT;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { data } = useSelector(yachtDetail.edit.selector.data);
  const [success, setSuccess] = useState(null);

  const onError = useCallback(
    (error) => {
      setError(
        error?.response?.data?.error?.message
          ? error?.response?.data?.error?.message
          : error?.message
      );
      setLoading(false);
      setSuccess(null);
    },
    [setLoading]
  );

  const onSuccess = (values) => {
    // Reset State
    setLoading(false);
    setError(null);
    setSuccess(true);

    // Dispatch Action
    dispatch(yachtDetail.query.stepper.action(values));
    dispatch(stepper.query.stepper.action({ next: 1 }));
    navigate(stepper.routes[1].path);
  };

  const onSubmitEdit = (values) => {
    setLoading(true);
    setSuccess(null);
    setError(null);

    fetchAPI(
      yachtDetail.query.edit.settings,
      { id: data.id },
      '',
      { ...values },
      jwt
    )
      .then(() => onSuccess(values))
      .catch((error) => onError(error));
  };

  const onSubmitAdd = (values) => {
    setLoading(true);
    setSuccess(null);
    setError(null);

    fetchAPI(yachtDetail.query.create.settings, {}, '', { ...values }, jwt)
      .then((response) => onSuccess({ ...values, id: response.data.id }))
      .catch((error) => onError(error));
  };

  // Edit
  if (data.id) {
    return (
      <Fragment>
        {loading && <Loading />}
        {error && <Alert severity='error'>{error}</Alert>}
        {success && <Alert severity='success'>{'Success'}</Alert>}

        <EditView
          data={data}
          conf={yachtDetail.edit.conf}
          onEditSubmit={onSubmitEdit}
          disabled={loading}
        >
          <NauticaKnownForm />
        </EditView>
      </Fragment>
    );
  }

  // Add
  return (
    <Fragment>
      {loading && <Loading />}
      {error && <Alert severity='error'>{error}</Alert>}
      <AddView
        conf={yachtDetail.create.conf}
        onAddSubmit={onSubmitAdd}
        disabled={loading}
      >
        <NauticaKnownForm />
      </AddView>
    </Fragment>
  );
};

export default YachtDetailPage;
