// CONF
import { LoginConf } from './conf/Login.conf';

//FORGOT
import { ForgotConf } from './conf/Forgot.conf';

// REDUX
import { reducer, actions } from './slice';

// SELECTOR
import { userSelector, tag } from './slice';

// SAGA
import { saga } from './saga';

// API
import { API } from './api';

export { reducer, saga, tag, LoginConf, ForgotConf };

const user = {
    tag,
    query: {
        login: {
            selector: userSelector,
            action: actions.userLoginAction,
            settings: API.login,
        },
    },
    login: {
        conf: LoginConf,
        to: '/home'
    },
};

export default user;
