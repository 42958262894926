import React, { useMemo } from 'react'
import _ from 'lodash'
import { Stack } from '@mui/material'
import yachtDocuments from '../../module/yachtDocuments';
import { useSelector } from 'react-redux';
import Typography from '../common/Typography.component';
import FilePresentIcon from '@mui/icons-material/FilePresent';

const DocumentsComponent = ({
    types = [],
    slugs = []
}) => {

    const { data } = useSelector(yachtDocuments.edit.selector.data);
    const filter = useMemo(() => {
        return _.filter(data, (doc) => _.includes(slugs, doc?.type))
    }, [data, slugs])


    return (
        <Stack>
            {filter.map((file, index) => {
                const document = _.find(types, (t) => t.attributes.slug === file.type)
                return (
                    <Stack direction='row' spacing={1} key={index}>
                        <FilePresentIcon color='primary'/>
                        <Typography component='a' href={`${process.env.REACT_APP_BASE_UPLOAD}${file.url}`} sx={{ color: 'grey.800' }}>
                            {file.name}
                        </Typography>
                        <Typography variant='subtitle1' sx={{ fontStyle: 'italic', color: 'grey.800' }}>
                            {`(${document.attributes.document_type})`}
                        </Typography>
                    </Stack>
                )
            })}
        </Stack>
    )
}

export default DocumentsComponent