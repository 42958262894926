import React, { useEffect, useState, Fragment } from 'react';
import MUIAutocomplete from '@mui/material/Autocomplete';
import MUITextField from '@mui/material/TextField';
import MUICircularProgress from '@mui/material/CircularProgress';
import Error from './Error.component';
import { useController, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { fetchAPI } from '../../../api/request';

const AsyncAutocomplete = ({
    name,
    required,
    url,
    api,
    label,
    ...props
}) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const jwt = process.env.REACT_APP_REGISTRATION_JWT
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        fetchAPI(api, {}, {}, {}, jwt).then((response) => {
            setLoading(false)
            setOptions(response)
        }).catch(() => {
            setLoading(false)
            setOptions([])
        })
    }, [jwt, api])

    const {
        control,
        formState: { errors },
    } = useFormContext();


    const {
        field: { ref, onChange, ...inputProps },
    } = useController({
        name: name,
        control: control,
        rules: { required },
    });

    return (
        <Box display='flex' flexDirection={'column'}>
            <MUIAutocomplete
                {...inputProps}
                ref={ref}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                options={options}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                loading={loading}
                renderInput={(params) => (
                    <MUITextField
                        {...params}
                        label={label}
                        required={required}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <Fragment>
                                    {loading ? (
                                        <MUICircularProgress color='inherit' size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </Fragment>
                            ),
                        }}
                        error={!!errors[name]}
                    />
                )}
                onChange={(e, data) => onChange(data)}
                {...props}
            />
            <Error error={errors[name]?.message} />
        </Box>
    );
};

AsyncAutocomplete.propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    label: PropTypes.string,
};

export default AsyncAutocomplete;
