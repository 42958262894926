import { createSelector } from "@reduxjs/toolkit"
import { dataSlice as createDataSLice } from "../../../core/data/index";

export const tag = 'company_detail'

// REDUCERS
const dataSlice = createDataSLice(`${tag}/data`, { data: {}, loading: false, error: null });

export const reducer = dataSlice.reducer

// ACTIONS
export const actions = {
    ...dataSlice.actions,
};

// EDIT
export const editStepperSelector = createSelector(
    (state) => state[tag],
    (value) => value
);
