import dayjs from 'dayjs';
import * as yup from 'yup';

export const CreateConf = {
  schema: yup.object().shape({
    country_code: yup.object().nullable(),
    phone_number: yup.string().required('Phone number is required'),
    name: yup.string().nullable().required('Name is required'),
    surname: yup.string().nullable().required('Last name is required'),
    start_date: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        const parsedDate = dayjs(originalValue, 'YYYY-MM-DD', true);
        return parsedDate?.isValid() ? parsedDate.toDate() : null;
      }),
    end_date: yup
      .date()
      .nullable()
      .when('start_date', (start_date, schema) =>
        start_date
          ? schema.min(dayjs(start_date), "End date can't be before Start date")
          : schema
      )
      .transform((value, originalValue) => {
        const parsedDate = dayjs(originalValue, 'YYYY-MM-DD', true);
        return parsedDate?.isValid() ? parsedDate.toDate() : null;
      }),
  }),
  defaultValues: {
    name: '',
    surname: '',
    country_code: null,
    crew_responsability: 'Rotate Captain',
    start_date: null,
    end_date: null,
    phone_number: '',
  },
  fields: [
    {
      id: 'name',
      name: 'name',
      type: 'text',
      label: 'First Name',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'surname',
      name: 'surname',
      type: 'text',
      label: 'Last Name',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'country_code',
      name: 'country_code',
      type: 'country-autocomplete',
      label: 'Country Code',
      required: false,
      xs: 4,
      sm: 4,
    },
    {
      id: 'phone_number',
      name: 'phone_number',
      type: 'number',
      label: 'Phone Number',
      required: true,
      xs: 8,
      sm: 8,
    },
    {
      id: 'start_date',
      name: 'start_date',
      type: 'date',
      label: 'Start date',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'end_date',
      name: 'end_date',
      type: 'date',
      label: 'End date',
      required: false,
      xs: 12,
      sm: 6,
    },
  ],
};
