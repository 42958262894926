import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme();

export const theme = createTheme({
  palette: {
    primary: {
      main: '#003761',
      dark: '#012c4d',
    },
    secondary: {
      main: '#00a4ad',
    },
    grey: {
      main: '#b8b1ab',
      light: '#ede7df',
    },
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'error' },
          style: {
            color: defaultTheme.palette.error.main,
          },
        },
      ],
    },
    MuiMobileStepper: {
      styleOverrides: {
        progress: {
          width: '100%',
        },
      },
    },
  },
  typography: {
    fontFamily: 'MuseoSans300',
  },
});
