export const API = {
  create: {
    path: '/yachts',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      return {
        data: {
          ...body,
          yacht_name: body?.yacht_name,
          yacht_type: body?.yacht_type,
          registry_type: body?.registry_type.id,
          port_of_registry: body?.port_of_registry,
          id_flag: body?.id_flag,
          loa: body?.loa,
          beam: body?.beam,
          draft: body?.draft,
          gross_tonn: body?.gross_tonn,
          id_nautica_known: body?.id_nautica_known.id,
          nautica_known_note: body?.nautica_known_note,
        },
      };
    },
  },
  update: {
    path: '/yachts/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      return {
        data: {
          ...body,
          yacht_name: body?.yacht_name,
          yacht_type: body?.yacht_type,
          registry_type: body?.registry_type.id,
          port_of_registry: body?.port_of_registry,
          id_flag: body?.id_flag,
          loa: body?.loa,
          beam: body?.beam,
          draft: body?.draft,
          gross_tonn: body?.gross_tonn,
          id_nautica_known: body?.id_nautica_known.id,
          nautica_known_note: body?.nautica_known_note,
        },
      };
    },
  },
};
