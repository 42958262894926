import * as yup from 'yup';

export const CreateConf = {
  schema: yup.object({
    username: yup.string().nullable().required('Username  is required'),
    email: yup
      .string()
      .nullable()
      .email('Please enter a valid email')
      .required('Email is required'),

    country_code: yup.object().nullable(),
    phone_number: yup.string().required('Phone number is required'),
    name: yup.string().nullable().required('Name is required'),
    surname: yup.string().nullable().required('Last name is required'),
  }),
  defaultValues: {
    username: '',
    name: '',
    surname: '',
    email: '',
    country_code: null,
    crew_responsability: 'Chef',
    phone_number: '',
  },
  fields: [
    {
      id: 'username',
      name: 'username',
      type: 'text',
      label: 'Username',
      xs: 12,
      sm: 6,
      required: true,
    },
    {
      id: 'email',
      name: 'email',
      type: 'text',
      label: 'Email',
      xs: 12,
      sm: 6,
      required: true,
    },
    {
      id: 'name',
      name: 'name',
      type: 'text',
      label: 'First Name',
      xs: 12,
      sm: 6,
      required: true,
    },
    {
      id: 'surname',
      name: 'surname',
      type: 'text',
      label: 'Last Name',
      xs: 12,
      sm: 6,
      required: true,
    },
    {
      id: 'country_code',
      name: 'country_code',
      type: 'country-autocomplete',
      label: 'Country Code',
      required: false,
      xs: 4,
      sm: 4,
    },
    {
      id: 'phone_number',
      name: 'phone_number',
      type: 'number',
      label: 'Phone Number',
      required: true,
      xs: 8,
      sm: 8,
    },
  ],
};
