import { createSelector } from '@reduxjs/toolkit';
import { dataSlice as createDataSLice } from '../../../core/data/index';
import _ from 'lodash';

export const tag = 'person_on_board_contacts';

// REDUCERS
const dataSlice = createDataSLice(`${tag}/data`, {
  data: {},
  loading: false,
  error: null,
});

export const reducer = dataSlice.reducer;

// ACTIONS
export const actions = {
  ...dataSlice.actions,
};

// EDIT
export const selectContactsSelector = createSelector(
  (state) => state['captain_contacts'],
  (state) => state['purser_contacts'],
  (state) => state['chiefEngineer_contacts'],
  (state) => state['chef_contacts'],
  (state) => state['firstOfficer_contacts'],
  (state) => state['chief_stewardess_contacts'],
  (state) => state['other_contacts'],
  (
    { data: captain_contacts },
    { data: purser_contacts },
    { data: chiefEngineer_contacts },
    { data: chef_contacts },
    { data: firstOfficer_contacts },
    { data: chief_stewardess_contacts },
    { data: other_contacts }
  ) => {
    const select = [
      {
        id: captain_contacts?.id,
        label: `${captain_contacts?.crew_responsability} - ${captain_contacts?.email}`,
      },
      {
        id: purser_contacts?.id,
        label: `${purser_contacts?.crew_responsability} - ${purser_contacts?.email}`,
      },
      {
        id: chiefEngineer_contacts?.id,
        label: `${chiefEngineer_contacts?.crew_responsability} - ${chiefEngineer_contacts?.email}`,
      },
      {
        id: chef_contacts?.id,
        label: `${chef_contacts?.crew_responsability} - ${chef_contacts?.email}`,
      },
      {
        id: firstOfficer_contacts?.id,
        label: `${firstOfficer_contacts?.crew_responsability} - ${firstOfficer_contacts?.email}`,
      },
      {
        id: chief_stewardess_contacts?.id,
        label: `${chief_stewardess_contacts?.crew_responsability} - ${chief_stewardess_contacts?.email}`,
      },
      {
        id: other_contacts?.id,
        label: `${other_contacts?.crew_responsability} - ${other_contacts?.email}`,
      },
    ];

    return _.filter(select, (o) => o.id !== undefined);
  }
);

export const editStepperSelector = createSelector(
  (state) => state[tag],
  (value) => value
);
