import React, { Fragment, useCallback, useState, useEffect } from 'react';
import Loading from '../../components/common/Loading.component';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../components/common/Alert.component';
import { fetchAPI } from '../../api/request';
import captainContacts from '../../module/captainContacts';
import yachtDocuments from '../../module/yachtDocuments';
import { useNavigate } from 'react-router';
import EditView from '../../views/Edit.view';
import stepper from '../../module/stepper';
import AddView from '../../views/Add.view';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _concat from 'lodash/concat';
import _flatten from 'lodash/flatten';
import _find from 'lodash/find';
import _drop from 'lodash/drop';
import DocumentsComponent from '../../components/documents/Documents.component';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '../../components/common/Typography.component';
import { Box, useTheme } from '@mui/material';
import Divider from '../../components/form/components/Divider.component';
import RotateCaptainContactPage from '../rotateCaptainContact/RotateCaptainContact.page';
import Button from '../../components/form/components/Button.component';
import dayjs from 'dayjs';

const CaptainContactsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwt = process.env.REACT_APP_REGISTRATION_JWT;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const idYacht = useSelector((state) => state.yacht_detail.data.id);
  const { data } = useSelector(captainContacts.edit.selector.data);
  const { data: documents } = useSelector(yachtDocuments.edit.selector.data);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    fetchAPI(yachtDocuments.query.types.settings, {}, '', {}, jwt).then(
      (response) => setTypes(response)
    );
  }, [jwt]);

  const onError = useCallback(
    (error) => {
      setError(
        error?.response?.data?.error?.message
          ? error?.response?.data?.error?.message
          : error?.message
      );
      setLoading(false);
      setSuccess(null);
    },
    [setLoading]
  );

  const onSuccess = (values, response) => {
    // Reset State
    setLoading(false);
    setError(null);
    setSuccess(true);
    // Dispatch Action
    dispatch(
      captainContacts.query.stepper.action({
        ..._omit(values, 'passaport'),
        id: response[0].data.id,
        birthdate: values?.birthdate
          ? dayjs(values?.birthdate).format('YYYY-MM-DD')
          : null,
      })
    );

    if (response.length > 1) {
      const docs = _drop(response).map((item) => ({
        ...item.data.attributes.file.data.attributes,
        type: item.data.attributes.id_document_type.data.attributes.slug,
      }));
      dispatch(
        yachtDocuments.query.stepper.action(
          _flatten(_concat([...documents], ...docs))
        )
      );
    }
  };

  const onSubmitEdit = (values) => {
    setLoading(true);
    setSuccess(null);
    fetchAPI(
      captainContacts.query.edit.settings,
      { id: data.id },
      '',
      { ...values },
      jwt
    )
      .then(() => onSuccess(values, [{ data: { id: data.id } }]))
      .catch((error) => onError(error));
  };

  const onSubmitAdd = (values) => {
    setLoading(true);
    setSuccess(null);
    setError(null);

    const promiseForm = fetchAPI(
      captainContacts.query.create.settings,
      {},
      '',
      {
        ..._omit(values, 'passaport'),
        crew_responsability: 'Captain',
        id_yacht: idYacht,
      },
      jwt
    );
    const documentType = _find(
      types,
      (t) => t.attributes.slug === 'captain-pass-port'
    );
    const promiseDocument = Array.from(values.passaport).map((file) =>
      fetchAPI(
        yachtDocuments.query.create.settings,
        {},
        '',
        { file, idYacht, documentType: documentType?.id },
        jwt
      )
    );
    Promise.all(_flatten([promiseForm, promiseDocument]))
      .then((response) => onSuccess(values, response))
      .catch((error) => onError(error));
  };

  const onClickNext = () => {
    dispatch(stepper.query.stepper.action({ next: 3 }));
    navigate(stepper.routes[3].path);
  };

  const theme = useTheme();

  if (types.length > 0) {
    if (!_isEmpty(data) && idYacht && documents.length > 0) {
      return (
        <Fragment>
          {loading && <Loading />}
          {error && <Alert severity='error'>{error}</Alert>}
          {success && <Alert severity='success'>{'Success'}</Alert>}

          <EditView
            data={data}
            conf={captainContacts.edit.conf}
            onEditSubmit={onSubmitEdit}
            disabled={loading}
          >
            <DocumentsComponent slugs={['captain-pass-port']} types={types} />
          </EditView>

          <Divider
            label={
              <>
                Enter contact information for <b> Rotational Captain</b> (if
                any, if not skip this section/step)
              </>
            }
            sx={{ fontWeight: 500 }}
          />

          <Accordion disableGutters elevation={1} sx={{ marginBottom: 5 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  width: '100%',
                  color: theme.palette.primary.main,
                }}
                variant='subtitle1'
              >
                Rotational Captain
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RotateCaptainContactPage />
            </AccordionDetails>
          </Accordion>

          <Box display='flex' alignItems='end' justifyContent={'end'} width={1}>
            <Button onClick={onClickNext} sx={{ width: 85 }}>
              Next
            </Button>
          </Box>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {loading && <Loading />}
        {error && <Alert severity='error'>{error}</Alert>}
        {success && <Alert severity='success'>{'Success'}</Alert>}

        <AddView
          conf={captainContacts.create.conf}
          onAddSubmit={onSubmitAdd}
          disabled={loading}
        />
      </Fragment>
    );
  }

  return null;
};

export default CaptainContactsPage;
