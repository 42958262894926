import React, { useEffect } from 'react';

import TextField from '../form/components/TextField.component';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import { API as nautica_known } from '../../module/nauticaKnown/api';
import AsyncAutocomplete from '../form/components/AsyncAutocomplete.component';

const NauticaKnownForm = () => {
  const { watch, setValue } = useFormContext();
  const watchNauticaKnow = watch('id_nautica_known');

  useEffect(() => {
    if (watchNauticaKnow === null || watchNauticaKnow?.name !== 'OTHER') {
      setValue('nautica_known_note', null);
    }
    // eslint-disable-next-line
  }, [watchNauticaKnow]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AsyncAutocomplete
          id='id_nautica_known'
          label='How did you know about Nautica Assistance?'
          name='id_nautica_known'
          required={true}
          api={nautica_known.select}
        />
      </Grid>
      {watchNauticaKnow?.name === 'OTHER' && (
        <Grid item xs={12}>
          <TextField
            name={'nautica_known_note'}
            id={'nautica_known_note'}
            label={'Note'}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default NauticaKnownForm;
