import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import stepper from '../module/stepper';
import { Box, Container, Paper, Stack, useTheme } from '@mui/material';
import _findIndex from 'lodash/findIndex';
import Typography from '../components/common/Typography.component';
import HeaderComponent from '../components/header/Header.component';
import FooterComponent from '../components/footer/Footer.component';

const RegistrationLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    stepper: {
      step: { total, current },
    },
  } = useSelector((state) => state.stepper);

  const idYacht = useSelector((state) => state.yacht_detail.data.id);

  useEffect(() => {
    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 10);
  }, [location.pathname]);

  useEffect(() => {
    const index = _findIndex(
      stepper.routes,
      (i) => i.path === location.pathname
    );
    dispatch(stepper.query.stepper.action({ next: index }));
    // eslint-disable-next-line
  }, [location.pathname, stepper.routes]);

  const theme = useTheme();

  useEffect(() => {
    if (idYacht === undefined) {
      navigate('/registration/yacht_details')
    }
  }, [idYacht,navigate])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <HeaderComponent />
      <Container disableGutters={true} maxWidth={'md'} sx={{ height: '100%' }}>
        <Typography
          textAlign='center'
          variant='h5'
          sx={{
            fontWeight: 'bold',
            padding: 1,
            color: theme.palette.primary.main,
          }}
        >
          {stepper.routes[current].title}
        </Typography>

        <Typography
          textAlign='center'
          variant='subtitle1'
          sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}
        >
          {`(${current + 1} / ${total})`}
        </Typography>

        <Typography
          variant='subtitle1'
          sx={{
            padding: 2,
            color: theme.palette.primary.main,
            textAlign: 'center',
          }}
        >
          {stepper.routes[current].description}
        </Typography>

        <Paper elevation={3}>
          <Stack direction={'column'} spacing={2} sx={{ padding: 2 }}>
            <Outlet />
          </Stack>
        </Paper>
      </Container>
      <Box sx={{ marginTop: 'auto' }}>
        <FooterComponent />
      </Box>
    </Box>
  );
};

export default RegistrationLayout;
