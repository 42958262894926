// CONF
import { CreateConf } from './conf/Create.conf'

// REDUX
import { reducer, actions, yachtSelector } from './slice'

// SELECTOR
import { tag } from './slice'

// API
import { API } from './api'


export { reducer, tag };

const yachtDetail = {
    tag,
    query: {
        stepper: {
            action: actions.dataChange,
        },
        create: {
            settings: API.create
        },
        edit: {
            settings: API.update
        },
    },
    create: {
        conf: CreateConf,
    },
    edit: {
        conf: CreateConf,
        selector: {
            data: yachtSelector,
        }
    },
}

export default yachtDetail