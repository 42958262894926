import { createSelector } from "@reduxjs/toolkit";
import { dataSlice as createDataSlice } from "../../../core/data/index";

export const tag = "yacht_documents";

// REDUCERS
const dataSlice = createDataSlice(`${tag}/data`, { data: [], loading: false, error: null });

export const reducer = dataSlice.reducer

// ACTIONS
export const actions = {
	...dataSlice.actions,
};

// EDIT
export const editStepperSelector = createSelector(
	(state) => state[tag],
	(value) => value
);
