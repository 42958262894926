export const PaymentTypes = [
  {
    label: 'VISA',
    id: 1,
  },
  {
    label: 'Mastercard',
    id: 2,
  },
  {
    label: 'AMEX',
    id: 3,
  },
];
