import React from 'react';
import MuiFormLabel from '@mui/material/FormLabel';
import MuiFormControl from '@mui/material/FormControl';
import MuiRadioGroup from '@mui/material/RadioGroup';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiRadio from '@mui/material/Radio';
import { useController, useFormContext } from 'react-hook-form';
import { Box, TextField, useTheme } from '@mui/material';
import Error from './Error.component';
import PropTypes from 'prop-types';

const RadioButtons = ({
  name,
  required,
  options = [],
  label,
  disabled = false,
  row = true,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    field: { ref, onChange, value, ...inputProps },
  } = useController({
    name: name,
    control: control,
    rules: { required },
  });

  const theme = useTheme();

  return (
    <Box display='flex' flexDirection={'column'} sx={{ padding: 1 }}>
      <MuiFormControl
        component='fieldset'
        variant='standard'
        required={required}
        error={!!errors[name]?.message}
      >
        <MuiFormLabel
          component='legend'
          sx={{ color: theme.palette.primary.main }}
        >
          {label}
        </MuiFormLabel>
        <MuiRadioGroup
          row={row}
          ref={ref}
          value={value}
          onChange={onChange}
          {...inputProps}
        >
          {options.map((item) => (
            <MuiFormControlLabel
              key={item.id}
              value={item.id}
              control={<MuiRadio disabled={disabled} />}
              label={
                item?.isInput ? (
                  <TextField
                    label='Other'
                    size='small'
                    onChange={(e) => onChange(e.target.value)}
                  />
                ) : (
                  item.label
                )
              }
            />
          ))}
        </MuiRadioGroup>
      </MuiFormControl>
      <Error error={errors[name]?.message} />
    </Box>
  );
};

RadioButtons.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
};

export default RadioButtons;
