import * as yup from 'yup';

export const CreateConf = {
  schema: yup.object({
    certificate_of_registry: yup.mixed().nullable().required(),
    insurance: yup.mixed().nullable().required(),
    crew_list: yup.mixed().nullable(),
  }),
  defaultValues: {
    certificate_of_registry: null,
    insurance: null,
    crew_list: null,
  },
  fields: [
    {
      id: 'certificate_of_registry',
      name: 'certificate_of_registry',
      label: 'Certificate Of Registry',
      type: 'upload',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'insurance',
      name: 'insurance',
      label: 'Insurance',
      type: 'upload',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'crew_list',
      name: 'crew_list',
      label: 'Crew List',
      type: 'upload',
      required: false,
      xs: 12,
      sm: 6,
    },
  ],
};
