export const API = {
	update: {
		path: "/yachts/{{id}}",
		method: "PUT",
		get: null,
		auth: true,
		fallback: null,
		adapter: (body) => {
			return {
				data: {
					...body,
				},
			};
		},
	},
};
