import React, { Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import invoiceDetail from '../../module/invoiceDetail';
import CompanyDetailsPage from '../companyDetails/CompanyDetails.page';
import NaturalPersonDetailsPage from '../naturalPersonDetails/NaturalPersonDetail.page';
import _find from 'lodash/find';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Divider from '../../components/form/components/Divider.component';
import { useTheme } from '@mui/material';

const InvoiceDetailPage = () => {
  const { data } = useSelector(invoiceDetail.edit.selector.data);
  const [invoiceType, setInvoiceType] = useState(
    data?.invoicing_entity || null
  );
  const field = useMemo(() =>
    _find(
      invoiceDetail.edit.conf.fields,
      (field) => field.id === 'invoicing_entity'
    ),[]
  );

  const handleChange = (event) => {
    setInvoiceType(event.target.value);
  };

  const theme = useTheme();

  return (
    <Fragment>
      <FormControl>
        <FormLabel sx={{ color: theme.palette.primary.main }}>
          {field.label}
        </FormLabel>
        <RadioGroup
          sx={{ color: theme.palette.primary.main }}
          row
          value={invoiceType}
          onChange={handleChange}
        >
          {field.options.map((radio) => (
            <FormControlLabel
              key={radio.id}
              value={radio.id}
              control={<Radio />}
              label={radio.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <Divider />
      {invoiceType === 'COMPANY' && <CompanyDetailsPage />}
      {invoiceType === 'NATURAL PERSON' && <NaturalPersonDetailsPage />}
    </Fragment>
  );
};

export default InvoiceDetailPage;
