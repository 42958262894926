import React, { Fragment, useCallback, useState } from 'react';
import Loading from '../../components/common/Loading.component';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../components/common/Alert.component';
import { fetchAPI } from '../../api/request';
import EditView from '../../views/Edit.view';
import AddView from '../../views/Add.view';
import _isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

const OtherContactPage = ({ responsability, conf }) => {
  const dispatch = useDispatch();
  const jwt = process.env.REACT_APP_REGISTRATION_JWT;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const idYacht = useSelector((state) => state.yacht_detail.data.id);
  const { data } = useSelector(conf.edit.selector.data);

  const onError = useCallback(
    (error) => {
      setError(
        error?.response?.data?.error?.message
          ? error?.response?.data?.error?.message
          : error?.message
      );
      setLoading(false);
      setSuccess(null)
    },
    [setLoading]
  );

  const onSuccess = (values) => {
    // Reset State
    setSuccess(true);
    setLoading(false);
    setError(null);

    // Dispatch Action
    dispatch(
      conf.query.stepper.action({
        ...values,
        birthdate: values?.birthdate
          ? dayjs(values?.birthdate).format('YYYY-MM-DD')
          : null,
      })
    );
  };

  const onSubmitEdit = (values) => {
    setError(null)
    setSuccess(null);
    setLoading(true);
    fetchAPI(conf.query.edit.settings, { id: data.id }, '', { ...values }, jwt)
      .then(() => onSuccess(values))
      .catch((error) => onError(error));
  };

  const onSubmitAdd = (values) => {
    setError(null)
    setSuccess(null);
    setLoading(true);
    fetchAPI(
      conf.query.create.settings,
      {},
      '',
      { ...values, crew_responsability: responsability, id_yacht: idYacht },
      jwt
    )
      .then((response) => onSuccess({ ...values, id: response.data.id }))
      .catch((error) => onError(error));
  };

  if (!_isEmpty(data) && idYacht) {
    return (
      <Fragment>
        {loading && <Loading />}
        {success && <Alert severity='success'>{'Success'}</Alert>}
        {error && <Alert severity='error'>{error}</Alert>}
        <EditView
          data={data}
          conf={conf.edit.conf}
          onEditSubmit={onSubmitEdit}
          disabled={loading}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      {loading && <Loading />}
      {success && <Alert severity='success'>{'Success'}</Alert>}
      {error && <Alert severity='error'>{error}</Alert>}
      <AddView
        conf={conf.create.conf}
        onAddSubmit={onSubmitAdd}
        disabled={loading}
      />
    </Fragment>
  );
};

export default OtherContactPage;
