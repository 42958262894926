import { Container, Toolbar, useMediaQuery } from '@mui/material';
import logoDesktop from '../../assets/logoDesktop.png';
import logoMobile from '../../assets/logoMobile.png';
import AppBar from '@mui/material/AppBar';

const HeaderComponent = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <AppBar position='relative' sx={{ backgroundColor: 'white' }}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters sx={{ padding: 1, alignItems:'center', justifyContent:'center' }}>
          <img
            src={isMobile ? logoMobile : logoDesktop}
            alt='Logo'
            style={{ height: 60 }}
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default HeaderComponent;
