import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Loading from '../../components/common/Loading.component';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../components/common/Alert.component';
import { fetchAPI } from '../../api/request';
import yachtDocuments from '../../module/yachtDocuments';
import stepper from '../../module/stepper';
import AddView from '../../views/Add.view';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Box, Stack } from '@mui/material';
import Button from '../../components/form/components/Button.component';
import DocumentsComponent from '../../components/documents/Documents.component';
import _flatten from 'lodash/flatten';

const YachtDocumentsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwt = process.env.REACT_APP_REGISTRATION_JWT;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const { data } = useSelector(yachtDocuments.edit.selector.data);
  const idYacht = useSelector((state) => state.yacht_detail.data.id);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    fetchAPI(yachtDocuments.query.types.settings, {}, '', {}, jwt).then(
      (response) => setTypes(response)
    );
    // eslint-disable-next-line
  }, []);

  const onSuccess = (response) => {
    // Reset State
    setSuccess(true);
    setLoading(false);
    setError(null);

    const documents = response.map((doc) => ({
      ...doc.data.attributes.file.data.attributes,
      type: doc.data.attributes.id_document_type.data.attributes.slug,
    }));

    // Dispatch Action
    dispatch(stepper.query.stepper.action({ next: 2 }));
    dispatch(yachtDocuments.query.stepper.action(documents));
    navigate(stepper.routes[2].path);
  };

  const onSkipStep = () => {
    dispatch(stepper.query.stepper.action({ next: 2 }));
    navigate(stepper.routes[2].path);
  };

  const onError = useCallback(
    (error) => {
      setError(
        error?.response?.data?.error?.message
          ? error?.response?.data?.error?.message
          : error?.message
      );
      setLoading(false);
      setSuccess(null)
    },
    [setLoading]
  );

  const onSubmitAdd = (values) => {
    setLoading(true);
    setSuccess(null);
    setError(null);
    
    const files = _.omitBy(values, _.isNil);
    const promises = _.map(_.keys(files), (key) => {
      let documentType = 0;
      switch (key) {
        case 'certificate_of_registry':
          documentType = _.find(
            types,
            (t) => t.attributes.slug === 'certificate-of-registry'
          );
          break;
        case 'insurance':
          documentType = _.find(
            types,
            (t) => t.attributes.slug === 'insurance'
          );
          break;
        case 'crew_list':
          documentType = _.find(
            types,
            (t) => t.attributes.slug === 'crew-list'
          );
          break;
        default:
          break;
      }
      return Array.from(values[key]).map((file) =>
        fetchAPI(
          yachtDocuments.query.create.settings,
          {},
          '',
          { file, idYacht,  documentType: documentType?.id },
          jwt
        )
      );
    });

    Promise.all(_flatten(promises))
      .then((response) => onSuccess(response))
      .catch((error) => onError(error));
  };

  if (types.length > 0) {
    if (data.length > 0) {
      return (
        <Stack spacing={1}>
          <DocumentsComponent
            slugs={['certificate-of-registry', 'insurance', 'crew-list']}
            types={types}
          />
          <Box display={'flex'} justifyContent='flex-end'>
            <Button
              variant='outlined'
              sx={{ mt: 1, mb: 2 }}
              onClick={onSkipStep}
            >
              Next Step
            </Button>
          </Box>
        </Stack>
      );
    }

    return (
      <Fragment>
        {loading && <Loading />}
        {success && <Alert severity='success'>{'Success: Add'}</Alert>}
        {error && <Alert severity='error'>{error}</Alert>}
        <AddView
          conf={yachtDocuments.create.conf}
          onAddSubmit={onSubmitAdd}
          disabled={loading}
        />
      </Fragment>
    );
  }

  return null;
};

export default YachtDocumentsPage;
