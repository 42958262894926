import { createSelector } from "@reduxjs/toolkit";
import { dataSlice as createDataSLice } from "../../../core/data/index";
import _ from "lodash";

export const tag = "rotate_captain_contacts";

// REDUCERS
const dataSlice = createDataSLice(`${tag}/data`, { data: {}, loading: false, error: null });

export const reducer = dataSlice.reducer

// ACTIONS
export const actions = {
	...dataSlice.actions,
};

// EDIT
export const editStepperSelector = createSelector(
	(state) => state[tag],
	(value) => {
		return !_.isEmpty(value?.data) && _.isEmpty(value?.data?.birthdate)
		  ? {
			  ...value,
			  data: {
				...value?.data,
				birthdate: null,
			  },
			}
		  : value;
	  }
);
