import * as yup from 'yup';

export const UpdateConf = {
  schema: yup.object({
    invoicing_entity: yup
      .string()
      .nullable()
      .required('Invoicing Entity is required'),
  }),
  defaultValues: {
    invoicing_entity: null,
  },
  fields: [
    {
      id: 'invoicing_entity',
      name: 'invoicing_entity',
      type: 'radio',
      label: 'Invoicing Entity',
      options: [
        { label: 'Company', id: 'COMPANY' },
        { label: 'Natural person', id: 'NATURAL PERSON' },
      ],
      required: true,
      disabled: true,
      xs: 12,
      sm: 12,
    },
  ],
};
