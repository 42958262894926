export const API = {
    nautica_known: {
        path: '/nautica-knowns?pagination[page]=1&pagination[pageSize]=1000',
        method: 'GET',
        get: null,
        auth: true,
        fallback: null,
    },

    select: {
        path: '/nautica-knowns?pagination[page]=1&pagination[pageSize]=1000',
        method: 'GET',
        get: null,
        auth: true,
        fallback: [],
        transform: (data) => data?.data?.map((item) => ({
            name: item.attributes.description,
            id: item.id
        }))
    },
}
