import React, { Fragment, useCallback, useState } from 'react';
import Loading from '../../components/common/Loading.component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import creditCardDetails from '../../module/creditCardDetails';
import EditView from '../../views/Edit.view';
import Alert from '../../components/common/Alert.component';
import stepper from '../../module/stepper';
import AddView from '../../views/Add.view';
import _isEmpty from 'lodash/isEmpty';

const CreditCardDetailsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const jwt = process.env.REACT_APP_REGISTRATION_JWT;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success] = useState(false);

  const { data } = useSelector(creditCardDetails.edit.selector.data);
  const id_yacht = useSelector((state) => state.yacht_detail.data.id);

  const onError = useCallback(
    (error) => {
      setError(
        error?.response?.data?.error?.message
          ? error?.response?.data?.error?.message
          : error?.message
      );
      setLoading(false);
    },

    [setLoading]
  );

  // const onSuccess = useCallback((values) => {
  //   setError(null);
  //   setLoading(false);
  //   setSuccess(true);
  //   // Dispatch Action
  //   dispatch(stepper.query.stepper.action({ next: 9 }));
  //   dispatch(creditCardDetails.query.stepper.action(values));
  //   navigate(stepper.routes[9].path);
  //   // eslint-disable-next-line
  // }, []);

  const onSubmitEdit = (values) => {
    dispatch(stepper.query.stepper.action({ next: 9 }));
    dispatch(creditCardDetails.query.stepper.action(values));
    navigate(stepper.routes[9].path);
  };

  return (
    <Fragment>
      {loading && <Loading />}
      {error && <Alert severity='error'>{error}</Alert>}
      {success && <Alert severity='success'>{'Success: Add'}</Alert>}
      {!_isEmpty(data) && id_yacht ? (
        <EditView
          data={data ? data : {}}
          conf={creditCardDetails.edit.conf}
          onEditSubmit={onSubmitEdit}
          disabled={loading}
          onEditError={onError}
        />
      ) : (
        <AddView
          conf={creditCardDetails.edit.conf}
          onAddSubmit={onSubmitEdit}
          disabled={loading}
        />
      )}
    </Fragment>
  );
};

export default CreditCardDetailsPage;
