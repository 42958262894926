// CONF
import { CreateConf } from './conf/Create.conf'


// REDUX
import { actions, editStepperSelector, reducer } from './slice'


// SELECTOR
import { tag } from './slice'

// API
import { API } from './api'



export { reducer, tag };

const naturalPersonDetails = {
    tag,
    query: {
        stepper: {
            action: actions.dataChange,
        },
        edit: {
            settings: API.update
        },
    },
    edit: {
        conf: CreateConf,
        selector: {
            data: editStepperSelector,
        }
    },
}

export default naturalPersonDetails