import React from 'react';
import MUITextField from '@mui/material/TextField';
import Error from './Error.component';
import { useController, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';

import PropTypes from 'prop-types';

const TextField = ({ name, required, ...props }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    field: { ref, ...inputProps },
  } = useController({
    name: name,
    control: control,
    rules: { required },
  });

  return (
    <Box display='flex' flexDirection={'column'}>
      <MUITextField
        error={!!errors[name]?.message}
        {...inputProps}
        inputRef={ref}
        {...props}
        required={required}
      />
      <Error error={errors[name]?.message} />
    </Box>
  );
};

TextField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
};

export default TextField;
