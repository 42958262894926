import * as yup from "yup";
import { getItem } from "../../../utility/Storage.utility";


export const LoginConf = {
	schema: yup.object({
		identifier: yup.string().required(),
		password: yup.string().required(),
	}),
	defaultValues: {
		identifier: 'giovanna.pichereddu@abinsula.com',
		password: 'giovanna'
	},
	initialState: {
		jwt: getItem('jwt'),
		user: getItem('user'),
		loading: false,
		error: null
	},
	fields: [{
		id: "identifier",
		name: "identifier",
		type: "text",
		label: "Username",
		required: true,
		xs: 12,
	}, {
		id: "password",
		name: "password",
		type: "password",
		label: "Password",
		required: true,
		xs: 12,
	}]
}