import * as yup from 'yup';
import { API as paymentPreferences } from '../../paymentPreferences/api';

export const CreateConf = {
  schema: yup.object({
    payment_preferences: yup.object().nullable().required('Is required'),
    fees_type: yup.string().nullable().required('Is required'),
  }),
  defaultValues: {
    payment_preferences: null,
    fees_type: null,
  },
  fields: [
    {
      id: 'payment_preferences',
      name: 'payment_preferences',
      type: 'async-checkbox_group',
      label: 'Payment Preference',
      options: [
        { label: '*Wire Transfer', id: 'WIRE TRANSFER' },
        { label: '**Cash', id: 'CASH' },
        { label: '***Credit Card', id: 'CREDIT CARD' },
      ],
      api: paymentPreferences.paymentType,
      required: true,
      xs: 12,
      sm: 12,
    },
    {
      id: 'fees_type',
      name: 'fees_type',
      type: 'radio',
      label:
        'Will you pay the marina fees directly or would you like us to take care of the payment for you? In this case we will apply a 10% fee for the advanced payment.',
      options: [
        { label: "I'll pay the ports directly", id: 'DIRECTLY' },
        {
          label: 'Nautica Assistance will take care of the berth payments',
          id: 'NOT DIRECTLY',
        },
      ],
      required: true,
      xs: 12,
      sm: 12,
    },
  ],
};
