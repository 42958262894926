import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fetchAPI } from '../../api/request';
const FooterComponent = () => {
  const theme = useTheme();

  const [versionBE, setVersioneBE] = useState(null);

  useEffect(() => {
    fetchAPI(
      {
        path: `/get-release-version`,
        method: 'GET',
        auth: true,
      },
      {},
      '',
      {},
      {}
    )
      .then((resp) => setVersioneBE(resp))
      .catch((error) => console.log(error));
  }, []);

  return (
    <Box
      sx={{
        height: { xs: '220px', sm: '160px', md: '135px' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: 2,
        backgroundColor: theme.palette.primary.main,
        marginTop: '40px',
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          color: 'white',
          textAlign: 'center',
          padding: { xs: '10px', md: '0' },
        }}
      >
        <b style={{ letterSpacing: '0.35px' }}>SHIPPING ADDRESS</b> | Yacht Name
        c/o Nautica Assistance, Via Corea 20, 07026 Olbia (SS), Italy
      </Typography>
      <Typography
        sx={{
          fontSize: '13px',
          color: 'white',
          padding: { xs: '15px', md: '0' },
        }}
      >
        info@nayacht.com | Sardinia | +39 0789 57607
      </Typography>

      <Box
        display='flex'
        sx={{
          color: 'white',
          padding: { xs: '15px', md: '0' },
          opacity: 0.8,
        }}
      >
        {process.env.REACT_APP_RELEASE_VERSION && (
          <Typography fontSize={11}>
            {`FE version ${process.env.REACT_APP_RELEASE_VERSION.replace(
              /[staging\-v]/g,
              ''
            )}`}
          </Typography>
        )}

        {versionBE && versionBE?.release_version && (
          <Typography
            fontSize={11}
            sx={{
              pl: 0.5,
            }}
          >
            {`| BE version ${versionBE?.release_version}`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default FooterComponent;
