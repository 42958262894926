import * as yup from 'yup';
import pdf_terms_and_conditions from '../../../assets/pdf/TERMS_AND_CONDITIONS.pdf';
export const CreateConf = {
  schema: yup.object({
    feedback: yup.boolean().nullable().required('Is required'),
    commercial: yup.boolean().nullable().required('Is required'),
    terms_and_condition: yup
      .boolean()
      .nullable()
      .test('Is required', 'Is required', (val) => val !== false)
      .required('Is required'),
    personal_email: yup.string().email().nullable(),
  }),
  defaultValues: {
    feedback: null,
    commercial: null,
    terms_and_condition: false,
    personal_email: null,
  },
  fields: [
    {
      id: 'feedback',
      name: 'feedback',
      type: 'radio',
      label:
        'I herewith agree that my personal feedback related ot the service provided by Nautica Assistance to the yacht and crew may be used by NA for marketing and advertising purposes along with my full name and yacht name in the form of publication on i.e. yachting websites, social newtwork and Nautica Assistance website. My feedback will not be used for other purposes.',
      options: [
        { label: 'I accept', id: true },
        { label: 'I do not accept', id: false },
      ],
      required: true,
      xs: 12,
      sm: 12,
    },
    {
      id: 'commercial',
      name: 'commercial',
      type: 'radio',
      label:
        'I herewith agree to receive NA newsletter with commercial information, propotions and/or invitations to events promoted or organized by Nautica Assistance. My email will not be used for other purposes.',
      options: [
        { label: 'I accept', id: true },
        { label: 'I do not accept', id: false },
      ],
      required: true,
      xs: 12,
      sm: 12,
    },
    {
      id: 'info',
      name: 'info',
      type: 'typography',
      label:
        'Please add your personal e-mail address if you wish to receive our newsletters also while you’re not on board.',
      required: true,
      xs: 12,
      sm: 12,
      sx: { fontSize: 16, color: 'grey' },
    },
    {
      id: 'personal_email',
      name: 'personal_email',
      type: 'text',
      label: 'Email',
      required: false,
      xs: 12,
      sm: 6,
      size: 'small',
    },
    {
      id: 'divider_0',
      type: 'divider',
      label: 'Terms & Conditions',
      xs: 12,
      sm: 12,
    },
    {
      id: 'iframe',
      type: 'iframe',
      url: pdf_terms_and_conditions,
      xs: 12,
      sm: 12,
    },
    {
      id: 'terms_and_condition',
      name: 'terms_and_condition',
      type: 'checkbox',
      required: true,
      label: 'I agree to Terms & Conditions',
      xs: 12,
      sm: 12,
    },
  ],
};
