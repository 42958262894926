import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Router from "./router/Router";
import { theme } from "./theme/MUITheme";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { configure } from "axios-hooks";
import Axios from "axios";
import { PersistGate } from "redux-persist/integration/react";

const axios = Axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
});
configure({ axios });

function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ThemeProvider theme={theme}>
					<BrowserRouter>
						<Router />
					</BrowserRouter>
				</ThemeProvider>
			</PersistGate>
		</Provider>
	);
}

export default App;
