import React, { Fragment, useCallback, useState, useEffect } from 'react';
import Loading from '../../components/common/Loading.component';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../components/common/Alert.component';
import { fetchAPI } from '../../api/request';
import { useNavigate } from 'react-router';
import companyDetails from '../../module/companyDetails';
import invoiceDetail from '../../module/invoiceDetail';
import yachtDocuments from '../../module/yachtDocuments';
import EditView from '../../views/Edit.view';
import stepper from '../../module/stepper';
import AddView from '../../views/Add.view';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _concat from 'lodash/concat';
import _flatten from 'lodash/flatten';
import _find from 'lodash/find';
import _drop from 'lodash/drop';
import DocumentsComponent from '../../components/documents/Documents.component';
import naturalPersonDetails from '../../module/naturalPersonDetails';

const CompanyDetailsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwt = process.env.REACT_APP_REGISTRATION_JWT;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { data } = useSelector(companyDetails.edit.selector.data);
  const { data: documents } = useSelector(yachtDocuments.edit.selector.data);
  const idYacht = useSelector((state) => state.yacht_detail.data.id);
  const [types, setTypes] = useState([]);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    fetchAPI(yachtDocuments.query.types.settings, {}, '', {}, jwt).then(
      (response) => setTypes(response)
    );
    // eslint-disable-next-line
  }, []);

  const onError = useCallback(
    (error) => {
      setError(
        error?.response?.data?.error?.message
          ? error?.response?.data?.error?.message
          : error?.message
      );
      setLoading(false);
      setSuccess(null);
    },
    [setLoading]
  );

  const onSuccess = (values, response) => {
    // Reset State
    setLoading(false);
    setError(null);
    setSuccess(true);
    
    // Dispatch Action
    dispatch(
      companyDetails.query.stepper.action({
        ..._omit(values, 'vat_document'),
        id: response[0].data.id,
      })
    );

    dispatch(naturalPersonDetails.query.stepper.action(null));

    dispatch(
      invoiceDetail.query.stepper.action({ invoicing_entity: 'COMPANY' })
    );

    if (response.length > 1) {
      const docs = _drop(response).map((item) => ({
        ...item.data.attributes.file.data.attributes,
        type: item.data.attributes.id_document_type.data.attributes.slug,
      }));
      dispatch(
        yachtDocuments.query.stepper.action(
          _flatten(_concat([...documents], ...docs))
        )
      );
    }

    dispatch(stepper.query.stepper.action({ next: 6 }));
    navigate(stepper.routes[6].path);
  };

  const onSubmitEdit = (values) => {
    setLoading(true);
    setSuccess(null);
    setError(null);

    const promiseForm = fetchAPI(
      companyDetails.query.edit.settings,
      { id: idYacht },
      '',
      {
        ..._omit(values, 'vat_document'),
        invoicing_entity: 'COMPANY',
        invoice_person_name: null,
        invoice_person_lastname: null,
        invoice_person_birthdate: null,
        invoice_person_place_of_birth: null,
      },
      jwt
    );

    if (values.vat_document) {
      const documentType = _find(
        types,
        (t) => t.attributes.slug === 'certificate-of-vat-or-incorporation'
      );
      const promiseDocument = Array.from(values.vat_document).map((file) =>
        fetchAPI(
          yachtDocuments.query.create.settings,
          {},
          '',
          {
            file,
            idYacht,
            documentType: documentType?.id,
          },
          jwt
        )
      );
      Promise.all(_flatten([promiseForm, promiseDocument]))
        .then((response) => onSuccess(values, response))
        .catch((error) => onError(error));
    } else {
      Promise.all([promiseForm])
        .then((response) => onSuccess(values, response))
        .catch((error) => onError(error));
    }
  };

  if (types.length > 0) {
    if (!_isEmpty(data) && idYacht) {
      return (
        <Fragment>
          {loading && <Loading />}
          {error && <Alert severity='error'>{error}</Alert>}
          {success && <Alert severity='success'>{'Success'}</Alert>}

          <EditView
            data={data}
            conf={companyDetails.edit.conf}
            onEditSubmit={onSubmitEdit}
            disabled={loading}
          >
            <DocumentsComponent
              slugs={['certificate-of-vat-or-incorporation']}
              types={types}
            />
          </EditView>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {loading && <Loading />}
        {error && <Alert severity='error'>{error}</Alert>}
        {success && <Alert severity='success'>{'Success'}</Alert>}

        <AddView
          conf={companyDetails.create.conf}
          onAddSubmit={onSubmitEdit}
          disabled={loading}
        />
      </Fragment>
    );
  }

  return null;
};

export default CompanyDetailsPage;
