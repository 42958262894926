import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { sagas } from '../sagas/sagas';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
//import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

import { reducer as LoginReducer, tag as LoginTag } from '../module/login';
import {
  reducer as personOnBoardContactsReducer,
  tag as personOnBoardContactsTag,
} from '../module/personOnBoardContacts';
import {
  reducer as PurserContactsReducer,
  tag as PurserContactsTag,
} from '../module/purser';
import {
  reducer as ChiefStewardessContactsReducer,
  tag as ChiefStewardessContactsTag,
} from '../module/chiefStewardessContacts';
import {
  reducer as FirstOfficerContactsReducer,
  tag as FirstOfficerContactsTag,
} from '../module/firstOfficerContacts';
import {
  reducer as ChefContactsReducer,
  tag as ChefContactsTag,
} from '../module/chefContacts';
import {
  reducer as ChiefEngineerContactsReducer,
  tag as ChiefEngineerContactsTag,
} from '../module/chiefEngineerContacts';
import {
  reducer as StepperReducer,
  tag as StepperTag,
} from '../module/stepper';
import {
  reducer as YachtDocumentsReducer,
  tag as YachtDocumentsTag,
} from '../module/yachtDocuments';
import {
  reducer as NaturalPersonDetailsReducer,
  tag as NaturalPersonDetailsTag,
} from '../module/naturalPersonDetails';
import {
  reducer as InvoiceDetailReducer,
  tag as InvoiceDetailTag,
} from '../module/invoiceDetail';
import {
  reducer as SignatureAndSubmissionReducer,
  tag as SignatureAndSubmissionTag,
} from '../module/signatureAndSubmission';
import {
  reducer as TermsOfPaymentReducer,
  tag as TermsOfPaymentTag,
} from '../module/termsOfPayment';
import {
  reducer as TermsAndConditionsReducer,
  tag as TermsAndConditionsTag,
} from '../module/termsAndConditions';
import {
  reducer as CreditCardDetailsReducer,
  tag as CreditCardDetailsTag,
} from '../module/creditCardDetails';
import {
  reducer as YachtDetailReducer,
  tag as YachtDetailTag,
} from '../module/yachtDetail';
import {
  reducer as CaptainContactsReducer,
  tag as CaptainContactsTag,
} from '../module/captainContacts';
import {
  reducer as CompanyDetailsReducer,
  tag as CompanyDetailsTag,
} from '../module/companyDetails';
import {
  reducer as RotateCaptainReducer,
  tag as RotateCaptainTag,
} from '../module/rotateCaptain';
import {
  reducer as otherContactsReducer,
  tag as otherContactsTag,
} from '../module/otherContacts';

export const resetStateAction = () => ({ type: 'RESET_STATE' });

const appReducer = combineReducers({
  [RotateCaptainTag]: RotateCaptainReducer,
  [YachtDetailTag]: YachtDetailReducer,
  [LoginTag]: LoginReducer,
  [CaptainContactsTag]: CaptainContactsReducer,
  [personOnBoardContactsTag]: personOnBoardContactsReducer,
  [PurserContactsTag]: PurserContactsReducer,
  [ChiefStewardessContactsTag]: ChiefStewardessContactsReducer,
  [otherContactsTag]: otherContactsReducer,
  [FirstOfficerContactsTag]: FirstOfficerContactsReducer,
  [ChefContactsTag]: ChefContactsReducer,
  [ChiefEngineerContactsTag]: ChiefEngineerContactsReducer,
  [CompanyDetailsTag]: CompanyDetailsReducer,
  [TermsAndConditionsTag]: TermsAndConditionsReducer,
  [TermsOfPaymentTag]: TermsOfPaymentReducer,
  [CreditCardDetailsTag]: CreditCardDetailsReducer,
  [SignatureAndSubmissionTag]: SignatureAndSubmissionReducer,
  [InvoiceDetailTag]: InvoiceDetailReducer,
  [NaturalPersonDetailsTag]: NaturalPersonDetailsReducer,
  [StepperTag]: StepperReducer,
  [YachtDocumentsTag]: YachtDocumentsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    state = undefined;
  }

  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware),
});
const persistor = persistStore(store);

sagaMiddleware.run(sagas);

export { store, persistor };
