import { createSlice } from '@reduxjs/toolkit';
import _includes from 'lodash/includes';
import _concat from 'lodash/concat';
import _flatten from 'lodash/flatten';

export const stepperSlice = (name) =>
  createSlice({
    name,
    initialState: {
      step: {
        total: 10,
        current: 0,
        completed: [],
      },
    },
    reducers: {
      stepChange: (state, action) => {
        state.step.current = action.payload.next;
        if (
          !_includes(state.step.completed, action.payload.next - 1) &&
          action.payload.next - 1 > 0
        ) {
          state.step.completed = _flatten(
            _concat([...state.step.completed], action.payload.next - 1)
          );
        }
      },
      loadingChange: (state) => {
        state.loading = true;
        state.error = null;
      },
      errorChange: (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.meta = null;
      },
    },
  });
