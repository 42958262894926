import React from 'react';
import MuiDivider from '@mui/material/Divider';
import MuiTypography from '@mui/material/Typography';
//import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';

const Divider = ({ label, sx }) => {
  const theme = useTheme();
  return (
    <MuiDivider
      sx={{
        mt: 2,
        mb: 2,
        whiteSpace: {
          xs: 'normal',
          sm: 'nowrap',
        },
      }}
    >
      {label && (
        <MuiTypography
          variant='subtitle2'
          sx={{
            fontWeight: 'bold',
            width: '100%',
            color: theme.palette.primary.main,
            ...sx,
          }}
        >
          {label}
        </MuiTypography>
      )}
    </MuiDivider>
  );
};

Divider.propTypes = {
  // label: PropTypes.string,
};

export default Divider;
