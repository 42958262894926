import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// import AuthRoute from "./AuthRoute";
// import LoginPage from "../pages/login/Login.page";
// import HomePage from "../pages/Home.page";
// import ForgotPage from "../pages/forgot/Forgot.page";

// Forms
import YachtDetailPage from '../pages/yachtDetail/YachtDetail.page';
import CaptainContactsPage from '../pages/captainContacts/CaptainContacts.page';
import PersonOnBoardContactsPage from '../pages/personOnBoard/PersonOnBoardContacts.page';
import InvoiceDetailPage from '../pages/invoiceDetail/InvoiceDetail.page';
import TermsAndConditionsPage from '../pages/termsAndConditions/TermsAndConditions.page';
import TermsOfPaymentPage from '../pages/termsOfPayment/TermsOfPayment.page';
import CreditCardDetailsPage from '../pages/creditCardDetails/CreditCardDetails.page';
import SignatureAndSubmissionPage from '../pages/signatureAndSubmission/SignatureAndSubmission.page';
import NotFoundPage from '../pages/NotFound.page';
import RegistrationLayout from '../layout/Registration.layout';
import OthersContactsPage from '../pages/othersContacts/OthersContacts.page';
import YachtDocumentsPage from '../pages/yachtDetail/YachtDocuments.page';
import SuccessRegistrationPage from '../pages/successRegistration/SuccessRegistration.page';
import InfoRegistrationFormPage from '../pages/infoRegistrationForm/InfoRegistrationForm.page';
import MainLayout from '../layout/Main.layout';

const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/home' />} />
      {/* <Route path="/home" element={<AuthRoute><HomePage /></AuthRoute>}/> */}
      {/* <Route path="/forgot" element={<ForgotPage />} /> */}
      {/* <Route path="/forgot" element={<ForgotPage />} />  */}

      <Route element={<MainLayout />}>
        <Route path='/home' element={<InfoRegistrationFormPage />} />
        <Route
          path='/registration/success'
          element={<SuccessRegistrationPage />}
        />
        <Route path='*' element={<NotFoundPage />} />
      </Route>

      <Route element={<RegistrationLayout />}>
        <Route
          path='/registration/yacht_details'
          element={<YachtDetailPage />}
        />
        <Route
          path='/registration/yacht_documents'
          element={<YachtDocumentsPage />}
        />
        <Route
          path='/registration/contacts_captain'
          element={<CaptainContactsPage />}
        />
        <Route
          path='/registration/contacts_others'
          element={<OthersContactsPage />}
        />
        <Route
          path='/registration/contacts_person_on_board'
          element={<PersonOnBoardContactsPage />}
        />
        <Route
          path='/registration/invoice_details'
          element={<InvoiceDetailPage />}
        />
        <Route
          path='/registration/terms_and_conditions'
          element={<TermsAndConditionsPage />}
        />
        <Route
          path='/registration/terms_of_payment'
          element={<TermsOfPaymentPage />}
        />
        <Route
          path='/registration/credit_card_details'
          element={<CreditCardDetailsPage />}
        />
        <Route
          path='/registration/signature_and_submission'
          element={<SignatureAndSubmissionPage />}
        />
      </Route>
    </Routes>
  );
};

export default Router;
