import { createSelector } from '@reduxjs/toolkit';
import { dataSlice as createDataSLice } from "../../../core/data/index";

export const tag = 'terms_and_conditions';

// REDUCERS
const dataSlice = createDataSLice(`${tag}/data`, null);

export const reducer = dataSlice.reducer

// ACTIONS
export const actions = {
    ...dataSlice.actions,
};

// EDIT
export const editStepperSelector = createSelector(
    (state) => state[tag],
    (value) => value
);
