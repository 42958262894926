export const API = {
  create: {
    path: '/yacht-documents?populate=%2A',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: ({ file, idYacht, documentType }) => {
      const formData = new FormData();
      const data = {
        id_document_type: documentType,
        id_yacht: idYacht,
      };

      formData.append(`files.file`, file, file?.name);
      formData.append('data', JSON.stringify(data));
      return formData;
    },
  },
  getTypes: {
    path: '/documents?populate=%2A',
    method: 'GET',
    get: 'data',
    auth: true,
    fallback: [],
  },
};
