import dayjs from 'dayjs';

export const API = {
  update: {
    path: '/yachts/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      let obj = body;
      if (body?.country_code) {
        obj = {
          ...body,
          invoice_phone_number: `${body?.country_code.phone}-${body?.invoice_phone_number}`,
          invoice_person_birthdate: body?.invoice_person_birthdate
            ? dayjs(body?.invoice_person_birthdate).format('YYYY-MM-DD')
            : null,
        };
        delete obj.country_code;
      }
      return {
        data: {
          ...obj,
          invoice_person_birthdate: body?.invoice_person_birthdate
            ? dayjs(body?.invoice_person_birthdate).format('YYYY-MM-DD')
            : null,
        },
      };
    },
  },
};
