import React from 'react';
import MUIAutocomplete from '@mui/material/Autocomplete';
import MUITextField from '@mui/material/TextField';
import Error from './Error.component';
import { useController, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';

import PropTypes from 'prop-types';

const Autocomplete = ({
    name,
    required,
    options,
    label,
    ...props
}) => {

    const { control, formState: { errors } } = useFormContext();


    const { field: { ref, onChange, ...inputProps } } = useController({
        name: name,
        control: control,
        rules: { required }
    });

    return (
        <Box display='flex' flexDirection={'column'}>
            <MUIAutocomplete
                ref={ref}
                {...inputProps}
                options={options}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => <MUITextField {...params} error={!!errors[name]} label={label} required={required} />}
                onChange={(e, data) => onChange(data)}
                {...props}
            />
            <Error error={errors[name]?.message} />
        </Box>

    )
}

Autocomplete.propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
};

export default Autocomplete

